
import React from 'react';
import {
  useGetUserTourQuery,
  useCompleteUserTourMutation,
  useDismissUserTourMutation,
} from '../store/Api';
import { useHistory } from 'react-router-dom';
import { Driver } from 'driver.js';

interface UsePageTourProps {
  tourKey: string;
  tourDriver: (onDestroy?: () => void, onComplete?: () => void, onDismiss?: () => void, enabledOptionalSteps?: string[]) => Driver;
  isOpen?: boolean;
  enabledOptionalSteps?: string[];
}

const usePageTour = ({
    tourKey,
    tourDriver,
    isOpen = false,
    enabledOptionalSteps,
}: UsePageTourProps) => {
  const getTour = useGetUserTourQuery(tourKey);
  const [completeTour] = useCompleteUserTourMutation();
  const [dismissTour] = useDismissUserTourMutation();
  const history = useHistory();

  const [tourOpen, setTourOpen] = React.useState<boolean>(isOpen);

  const destroyTour = () => {
    setTourOpen(false);
  };

  const completeTourHandler = () => {
    completeTour(tourKey);
    tour.destroy();
  };

  const closeTour = () => {
    dismissTour(tourKey);
    tour.destroy();
  };

  const tour = tourDriver(destroyTour, completeTourHandler, closeTour, enabledOptionalSteps);

  React.useEffect(() => {
    if (
      getTour.data?.enabled &&
      !getTour.data?.completed &&
      !getTour.data?.dismissed
    ) {
      setTourOpen(true);
      tour.drive();
    }
  }, [getTour.data]);

  React.useEffect(() => {
    const unblock = history.block(() => {
      if (tourOpen) {
        return false; // Prevent navigation
      }
    });

    return () => {
      unblock();
    };
  }, [tourOpen, history]);

  return {
    tourOpen,
    destroyTour,
    completeTourHandler,
    closeTour,
  };
};

export default usePageTour;