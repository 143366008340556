import React from 'react'
import {IWorkspaceItem} from "../../models/Workspace";
import {useGetWorkspaceQuery, usePutWorkspaceItemDetailsMutation} from "../../store/Api";
import {TextField} from "@fluentui/react";
import {calculatePrice} from "../../logic/Product";

interface PriceEntryProps {
    item: IWorkspaceItem;
    isExample?: boolean;
}

const PriceEntry: React.FC<PriceEntryProps> = (props) => {
    const workspace = useGetWorkspaceQuery(props.item.workspace, {
        skip: props.item.workspace === "example workspace"
    });
    const [detailsChange] = usePutWorkspaceItemDetailsMutation();
    const [currentVal, setCurrentVal] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (props.item.details.price != null && props.item.details?.price !== 0) {
            setCurrentVal(props.item.details.price.toString());
        }
    }, [props.item.details?.price])

    let calculatedPrice = props.item.product
        ? calculatePrice(props.item.product, props.item.details)
        : undefined;

    if((calculatedPrice === undefined || calculatedPrice === 0) && props.item.product) {
        calculatedPrice = calculatePrice(props.item.product, props.item.details, workspace?.data?.defaultMarkup, workspace?.data?.defaultListDiscount)
    }

    if (calculatedPrice === 0) calculatedPrice = undefined;

    const update = (v: string | undefined) => {
        if(props.isExample) return;
        detailsChange({
            workspace: props.item.workspace,
            product: props.item.id,
            details: {
                ...props.item.details,
                price: v ? parseFloat(v) : undefined,
            }
        })
    }

    return (<>
        <TextField
            placeholder={calculatedPrice?.toString() ?? 'Not Set'}
            value={currentVal}
            prefix={'$'}
            type={'number'}
            className={'italic-placeholder'}
            onBlur={(e) => update(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    update(e.currentTarget.value);
                }
            }}
            onChange={(e, v) => {
                setCurrentVal(v);
            }}
        />
    </>)
}

export default PriceEntry;