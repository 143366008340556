import React from "react";
import {
    useGetStorefrontProductCountQuery,
    useGetStorefrontsQuery, useLazyGetSavedSearchOnStorefrontCountQuery
} from "../../store/Api";
import SavedSearchStorefrontEntryDetails from "./SavedSearchStorefrontEntryDetails";
import {ISavedSearchStorefrontDetails} from "../../models/Search";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import MarkupTypeSelection from "../Storefront/MarkupTypeSelection";
import NumberField from "../Inputs/NumberField";

interface ISavedSearchStorefrontSettings{
    storefrontOverrideMarkup?: number;
    storefrontOverrideMarkupType?: string;
    minimumPrice?: number;
    minimumCost?: number;
    storefronts?: {[key: string]: ISavedSearchStorefrontDetails};
    includedStorefronts?: string[];
    onIncludedStorefrontsChange: (includedStorefronts: string[]) => void;
    onStorefrontsChange: (storefronts: {[key:string]: ISavedSearchStorefrontDetails}) => void;
    onStorefrontOverrideMarkupChange: (markup: number | undefined) => void;
    onStorefrontOverrideMarkupTypeChange: (markupType: string | undefined) => void;
    onStorefrontMinimumCostChange: (minimumCost: number | undefined) => void;
    onStorefrontMinimumPriceChange: (minimumPrice: number | undefined) => void;


}
const SavedSearchStorefrontSettings: React.FC<ISavedSearchStorefrontSettings> = (props) => {
    const storefronts = useGetStorefrontsQuery();
    const [reachedTotalStorefrontLimit, setReachedTotalStorefrontLimit] = React.useState<boolean>(false);
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const productCount = useGetStorefrontProductCountQuery();
    React.useEffect(() => {
        if (org?.storefrontProductLimit != null && productCount.data?.count != null) {
            setReachedTotalStorefrontLimit(productCount.data.count > org.storefrontProductLimit);
        }
    }, [org, productCount.data]);
    
    return (
        <div style={{paddingTop:'1em'}}>
            <h3>Default Storefront Settings</h3>
            {reachedTotalStorefrontLimit && <p>Product limit reached. Please remove products from storefronts to add more.</p>}
            <NumberField
                disabled={reachedTotalStorefrontLimit}
                label='Minimum Price'
                value={props.minimumPrice}
                onChange={props.onStorefrontMinimumPriceChange}
            />
            <NumberField
                disabled={reachedTotalStorefrontLimit}
                label='Minimum Cost'
                value={props.minimumCost}
                onChange={props.onStorefrontMinimumCostChange}
            />
            <NumberField
                disabled={reachedTotalStorefrontLimit}
                label='Override Markup'
                value={props.storefrontOverrideMarkup}
                onChange={props.onStorefrontOverrideMarkupChange}
            />
            <MarkupTypeSelection
                disabled={reachedTotalStorefrontLimit}
                label='Override Markup Type'
                value={props.storefrontOverrideMarkupType}
                onChange={props.onStorefrontOverrideMarkupTypeChange}
            />
            <br/>
            <table className='data-table'>
                <thead>
                <tr>
                    <th></th>
                    <th>Storefront</th>
                    <th>Included</th>
                    <th>Markup</th>
                    <th>Markup Type</th>
                    <th>Minimum Price</th>
                    <th>Minimum Cost</th>
                </tr>
                </thead>
                <tbody>
                {storefronts.data?.items?.map((storefront) => {
                    if(!props.storefronts?.[storefront.id ?? '']){
                        return null;
                    }
                    return(
                        <SavedSearchStorefrontEntryDetails
                            reachedTotalStorefrontLimit={reachedTotalStorefrontLimit}
                            key={storefront.id}
                            storefrontId={storefront.id}
                            storefrontName={storefront.name}
                            included={props.includedStorefronts?.includes(storefront.id ?? '')}
                            onIncludedChange={(included) => props.onIncludedStorefrontsChange(included ? [...(props.includedStorefronts ?? []), storefront.id ?? ''] : (props.includedStorefronts ?? []).filter((id) => id !== storefront.id))}
                            storefrontEntry={props.storefronts?.[storefront.id ?? '']}
                            onStorefrontEntryChange={(storefrontEntry) => {
                                props.onStorefrontsChange({
                                ...props.storefronts,
                                [storefront.id ?? '']: storefrontEntry})
                            }
                        }
                        />
                )})}
                </tbody>
            </table>

        </div>)
}

export default SavedSearchStorefrontSettings;