import React, {useState} from 'react';
import { IStorefrontSettings } from '../../models/Storefront';
import { useGetStorefrontsQuery, useLazyGetStorefrontLoginLinkQuery } from '../../store/Api';
import { CommandBar, IColumn, ICommandBarItemProps, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import {
    ButtonColumnGenerator,
    ColumnGenerator,
    DisableableButtonColumnGenerator
} from '../../logic/Columns/ColumnGenerator';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

interface IStorefrontsTableProps {
    onDetailsClick: (storefront: IStorefrontSettings) => void;
    onNewClick: () => void;
}

const StorefrontsTable: React.FC<IStorefrontsTableProps> = (props) => {
    const [getStorefrontLink] = useLazyGetStorefrontLoginLinkQuery()
    const [loadingStorefrontLink, setLoadingStorefrontLink] = useState<string | undefined>();
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const storefronts = useGetStorefrontsQuery();
    const remaining = org?.storefrontLimit == null || storefronts.data?.total == null
        ? null
        : org?.storefrontLimit - storefronts.data?.total;

    const storefrontLink = (item: IStorefrontSettings) => {
        if (org?.storefrontDomain == null) return <>Unknown</>
        let domain = org.storefrontDomain;
        if (item.domain) domain = item.domain;
        return <a href={`https://${domain}/${org.id}/${item.subUrl}/login`} target='_blank'>Login Page</a>
    }

    const columns: IColumn[] = [
        ColumnGenerator('Name', 'name', 100),
        ColumnGenerator('Sub URL', 'subUrl', 100, 200),
        ColumnGenerator('Link', 'link', 100, 200, (item: IStorefrontSettings) => storefrontLink(item)),
        DisableableButtonColumnGenerator((item: IStorefrontSettings) => {
                if(item.subUrl) {
                    setLoadingStorefrontLink(item.subUrl)
                    getStorefrontLink(item.subUrl).unwrap().then( (resp) => {if(resp.redirectUrl){
                        setLoadingStorefrontLink(undefined)
                        window.open(resp.redirectUrl, '_blank');
                    }})
                }
            }, 
            'Preview', 
            'Preview', 
            true, 
            (item) => {return !!item; },
            undefined,
            (item: IStorefrontSettings) => {
                return !!(loadingStorefrontLink && loadingStorefrontLink == item.subUrl);
            }
        ),
        ColumnGenerator('Enabled', 'enabled', 100, 100, (item: IStorefrontSettings) => <>{item.enabled ? 'Yes' : 'No'}</>),
        ButtonColumnGenerator((item: IStorefrontSettings) => props.onDetailsClick(item), 'Details'),
    ]

    const commands: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: remaining == null ? 'Loading' : `New Storefront (${remaining} remaining)`,
            iconProps: { iconName: 'Add' },
            onClick: props.onNewClick,
            disabled: storefronts.isLoading || (org?.storefrontLimit != null && storefronts.data?.total != null && storefronts.data.total >= org.storefrontLimit),
        }
    ]

    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            iconOnly: true,
            onClick: () => {storefronts.refetch()},
        }
    ]

    return (
        <div>
            <CommandBar items={commands} farItems={farCommands} />
            <ShimmeredDetailsList
                enableShimmer={storefronts.isLoading}
                items={storefronts.data?.items ?? []}
                columns={columns}
                selectionMode={SelectionMode.none}
                />
        </div>
    );
}


export default StorefrontsTable;