import React, {useEffect} from "react";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import {CostDisplay} from "../Products/CostDisplay";
import {InStockDisplay} from "../Products/InStockDisplay";
import {IColumn, SelectionMode, Separator, ShimmeredDetailsList} from "@fluentui/react";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import AddToWorkspaceButton from "../ProductDetails/AddToWorkspaceButton";
import {useGetMultipleItemDetailsQuery} from "../../store/Api";
import {IProductLink} from "../../models/Search";

interface ILinkedItemsTableProps {
    catalog: string;
    items: IProductLink[];
    header: string;
    filterStock: boolean;
    editMode: boolean;
}

export const LinkedItemsTable: React.FC<ILinkedItemsTableProps> = (props) => {
    const getProducts = useGetMultipleItemDetailsQuery({catalog: props.catalog, ids: props.items.map(l => l.value)});
    
    const [effectiveProducts, setEffectiveProducts] = React.useState<INormalizedProduct[]>([]);
    useEffect(() => {
        if(getProducts.data) {
            if(props.filterStock){
                const filteredProducts = getProducts.data.filter(p => p.vendors?.some(v => v.inStock && v?.cost && v.cost > 0 && v.onHand && v?.onHand > 0));
                setEffectiveProducts(filteredProducts);
            }
            else {
                setEffectiveProducts(getProducts.data);
            }
        }
    }, [getProducts.data, props.filterStock]);
    
    const columns: IColumn[] = [
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('Availability', 'availability', 100, 100, (item: INormalizedProduct) => <InStockDisplay hideWarehouses product={item} loadingWarehouses={true} />),
        ColumnGenerator('Cost', 'cost', 200, 200, (item: INormalizedProduct) => <CostDisplay product={item} />),
        ColumnGenerator('Add to Workspace', 'addToWorkspace', 150, 100, (item: INormalizedProduct) => <AddToWorkspaceButton product={item} /> )
    ]

    return (
        <div>
            <Separator>{props.header}</Separator>
            <ShimmeredDetailsList enableShimmer={getProducts.isLoading} items={effectiveProducts ?? []} selectionMode={SelectionMode.none} columns={columns} />
        </div>
    )
};
