import React from 'react';
import { useGetFavoritesProductsQuery } from '../../store/Api';
import { INormalizedProduct } from '../../models/NormalizedProduct';
import DetailsModal from '../DetailsModal/DetailsModal';
import CAN, {EDIT_ITEM} from "../../permissions/ability";
import { CostDisplay } from '../Products/CostDisplay';

interface IFavoritesListProps {
    useDemoData?: boolean;
}

const DEMO_FAVORITES: INormalizedProduct[] = [
    {
        id: '1',
        name: 'Demo Laptop',
        vendors: [{
            vendorName: 'Demo Vendor',
            cost: 1000,
            vendorPartNumber: '12345',
            inStock: true
        }],
        favorite: {user: true, company: false},
        catalog: 'technology',
        attributes: {},
        facets: {},
        filters: {},
        images: [],
        links: []
    },
    {
        id: '2',
        name: 'Installation Services',
        vendors: [{
            vendorName: 'Demo Vendor',
            cost: 100,
            vendorPartNumber: '54321',
            inStock: true
        }],
        favorite: {user: false, company: true},
        catalog: 'services',
        attributes: {},
        facets: {},
        filters: {},
        images: [],
        links: []
    },
    {
        id: '3',
        name: 'Demo Monitor',
        vendors: [{
            vendorName: 'Demo Vendor',
            cost: 200,
            vendorPartNumber: '67890',
            inStock: true
        }],
        favorite: {user: true, company: true},
        catalog: 'technology',
        attributes: {},
        facets: {},
        filters: {},
        images: [],
        links: []
    },
    {
        id: '5',
        name: 'Microsoft 365 E3',
        vendors: [{
            vendorName: 'Microsoft',
            cost: 22.39,
            vendorPartNumber: 'MS365',
            inStock: true
        }],
        favorite: {user: true, company: true},
        catalog: 'technology',
        attributes: {},
        facets: {},
        filters: {},
        images: [],
        links: []
    }
]

const FavoritesList: React.FC<IFavoritesListProps> = (props) => {
    const getFavorites = useGetFavoritesProductsQuery();
    const [favorites, setFavorites] = React.useState<INormalizedProduct[]>([]);
    const [selectedProduct, setSelectedProduct] = React.useState<INormalizedProduct | undefined>();

    React.useEffect(() => {
        if (props.useDemoData && (!getFavorites.data?.products || getFavorites.data.products.length === 0)) {
            setFavorites(DEMO_FAVORITES);
        } else {
            setFavorites(getFavorites.data?.products ?? []);
        }
    }, [getFavorites.data, props.useDemoData]);

    const canEditItem = CAN(EDIT_ITEM);

    if (favorites.length === 0) return null;

    return (
        <div className={`home-list fade-in favorites-list`}>
            <h3>Favorites</h3>
            <table>
                {favorites.slice(0, 5).map((product) => (
                    <tr key={product.id} onClick={() => {if (!props.useDemoData) setSelectedProduct(product)}}>
                        <td>{product.name}</td>
                        <td style={{textAlign:'right'}}><CostDisplay product={product} /></td>
                    </tr>
                ))}
            </table>
        {selectedProduct &&
            <DetailsModal product={selectedProduct.id} catalog={selectedProduct.catalog} onClose={() => setSelectedProduct(undefined)} open={true} preventEdit={!canEditItem}/>}
        </div>
    );
}

export default FavoritesList;