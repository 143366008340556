import { Callout, DefaultEffects, DirectionalHint, ICalloutContentStyles, Icon, SearchBox, Spinner } from '@fluentui/react';
import React, { useCallback, useRef } from 'react';
import { useLazyGetQuicksearchQuery, useLazyGetWorkspacesQuery, useSetSelectedWorkspaceMutation } from '../../store/Api';
import { debounce } from 'lodash';
import './HomeSearchBar.css';
import { useHistory } from 'react-router-dom';

const WORKSPACE_TYPE = 'Workspaces';
const PRODUCT_TYPE = 'Products';
const KEYWORD_SEARCH_TYPE = 'KeywordSearch';

interface IQuicksearchResult {
    type: string;
    name: string;
    description: string | React.ReactNode;
    id: string;
}

interface IHomeSearchBarProps {
    quicksearchEnabled?: boolean;
    onProductSearch?: (productId: string) => void;
    onWorkspaceSearch?: (workspaceId: string) => void;
    onProductSelected?: (productId: string) => void;
    onWorkspaceSelected?: (workspaceId: string) => void;
}

const HomeSearchBar: React.FC<IHomeSearchBarProps> = (props) => {
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    const [quicksearch] = useLazyGetQuicksearchQuery();
    const [getWorkspaces] = useLazyGetWorkspacesQuery();
    const [quicksearchResults, setQuicksearchResults] = React.useState<IQuicksearchResult[] | undefined>(undefined);
    const searchboxRef = useRef<HTMLDivElement>(null);
    const [setSelectedWorkspace] = useSetSelectedWorkspaceMutation();
    const history = useHistory();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [fetchingData, setFetchingData] = React.useState(false);

    const search = useCallback(async (query: string) => {
        setQuicksearchResults(undefined);
        if (query.length > 0) {
            setFetchingData(true);
            const qsResults: IQuicksearchResult[] = [];
            const pResults = await quicksearch({ value: query });
            if (pResults.data?.productNamesWithIds) {
                for(const [name, id] of Object.entries(pResults.data.productNamesWithIds)) {
                    qsResults.push({ type: PRODUCT_TYPE, name, id, description: '' });
                }
            }
            const wResults = await getWorkspaces({ searchText: query, filter: 'allFilter' })
            if (wResults.data) {
                for(const workspace of wResults.data.slice(0, 5)) {
                    qsResults.push({ type: WORKSPACE_TYPE, name: workspace.name, id: workspace.id, description: '' });
                }
            }
            setQuicksearchResults(qsResults);
            setFetchingData(false);
        }
    }, [quicksearch]);

    const debouncedSearch = useRef(debounce((query: string) => search(query), 200)).current;

    React.useEffect(() => {
        setSelectedIndex(0);
        if (props.quicksearchEnabled) {
            debouncedSearch(searchQuery);
        }
    }, [searchQuery, props.quicksearchEnabled]);

    const handleChange = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
        setSearchQuery(newValue || '');
    }

    const handleKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                if (quicksearchResults) {
                    setSelectedIndex(prev => 
                        Math.min(prev + 1, quicksearchResults.length));
                }
                break;
            case 'ArrowUp':
                event.preventDefault();
                setSelectedIndex(prev => 
                    Math.max(prev - 1, 0));
                break;
            case 'Enter':
                event.preventDefault();
                event.stopPropagation();
                if (selectedIndex === 0) {
                    props.onProductSearch?.(searchQuery);
                }
                if (quicksearchResults && quicksearchResults[selectedIndex - 1]) {
                    const result = quicksearchResults[selectedIndex - 1];
                    switch (result.type) {
                        case PRODUCT_TYPE:
                            props.onProductSelected?.(result.id);
                            break;
                        case WORKSPACE_TYPE:
                            handleWsSelected(result.id);
                            break;
                        case KEYWORD_SEARCH_TYPE:
                            props.onProductSearch?.(result.name);
                            break;
                    }
                }
                break;
        }
    }

    const handleWsSelected = async (workspaceId: string) => {
        await setSelectedWorkspace(workspaceId);
        history.push(`/workspace/${workspaceId}`);
    }

    const handleProductSelected = (productId: string) => {
        props.onProductSelected?.(productId);
    }

    const typeAheadCalloutStyle: Partial<ICalloutContentStyles> = {
        root: {
            boxShadow: DefaultEffects.elevation4,
            borderRadius: 2,
            marginTop: 0,
            width: '100%',
            minWidth: "200px",
            overflow: "hidden",
            //maxHeight: '500px!important'
            top: "0px!important",
            left: "0px!important",
            //getLeftShift(),
            selectors: {
                "@media(max-width: 600px)": {
                    top: "0px",
                    left: "0px!important",
                    //left: getLeftShift(),
                    // bottom: "-200px!important",
                    minWidth: "200px",
                },
            },
        },
        container: {
            zIndex: 3,
            position: "relative",
            width: '100%',
        },
        calloutMain: {
            minHeight: "fit-content",
            maxHeight: "500px!important",
            height: "100%",
            width: '100%',
        },
    };

    return (
        <div className='home-search-bar'>
            <div ref={searchboxRef}>
                <SearchBox
                    placeholder={'Search products and workspaces'}
                    onChange={handleChange}
                    onKeyDownCapture={handleKeypress}
                    // onKeyDown={handleKeypress}
                    value={searchQuery}
                    styles={{
                        field: {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            height: '3em',
                        },
                        root: {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            height: '3em',
                        }
                    }}
                    autoComplete='off'
                />
            </div>
            {searchQuery && <Callout
            styles={typeAheadCalloutStyle}
            isBeakVisible={false}
            target={searchboxRef.current}
            directionalHint={DirectionalHint.bottomLeftEdge}
            directionalHintForRTL={DirectionalHint.bottomRightEdge}
            doNotLayer={true}
            >
                <table className='quicksearch-results'>
                    <UniversalSearchResultDisplay
                        key={'KeywordSearch'}
                        result={{ type: KEYWORD_SEARCH_TYPE, name: searchQuery, description: '', id: '' }}
                        selected={selectedIndex === 0}
                        onKeywordSearchSelected={props.onProductSearch}
                        onHover={() => setSelectedIndex(0)}
                    />
                    {fetchingData && <tr><td style={{padding:'0.5em'}} colSpan={2}><Spinner label='Loading Results' labelPosition='right' /></td></tr>}
                    {!fetchingData && quicksearchResults?.map((result, i) => (
                        <UniversalSearchResultDisplay
                            key={result.id}
                            result={result}
                            selected={selectedIndex === i + 1}
                            onWorkspaceSelected={handleWsSelected}
                            onProductSelected={handleProductSelected}
                            onHover={() => setSelectedIndex(i + 1)}
                            />
                    ))}
                </table>
            </Callout>}
        </div>
    );
}

interface IUniversalSearchResultDisplay {
    result: IQuicksearchResult;
    onKeywordSearchSelected?: (keyword: string) => void;
    onProductSelected?: (productId: string) => void;
    onWorkspaceSelected?: (workspaceId: string) => void;
    selected?: boolean;
    onHover?: () => void;
}


const UniversalSearchResultDisplay: React.FC<IUniversalSearchResultDisplay> = (props) => {
    let icon: string | undefined = undefined;
    switch (props.result.type) {
        case PRODUCT_TYPE:
            icon = 'Product';
            break;
        case WORKSPACE_TYPE:
            icon = 'ProductVariant';
            break;
        case KEYWORD_SEARCH_TYPE:
            icon = 'Search';
            break;
    }

    const handleClick = () => {
        switch (props.result.type) {
            case PRODUCT_TYPE:
                props.onProductSelected?.(props.result.id);
                break;
            case WORKSPACE_TYPE:
                props.onWorkspaceSelected?.(props.result.id);
                break;
            case KEYWORD_SEARCH_TYPE:
                props.onKeywordSearchSelected?.(props.result.name);
                break;
        }
    }

    const normalizeName = () => {
        if (props.result.type === WORKSPACE_TYPE) {
            if (props.result.name.toLowerCase().endsWith('workspace')) {
                return props.result.name;
            }
            return `${props.result.name} Workspace`;
        }
        if (props.result.type === KEYWORD_SEARCH_TYPE) {
            return `Search products for "${props.result.name}"`;
        }
        return props.result.name;
    }

    return (
        <tr className={`quicksearch-result ${props.selected ? 'selected' : ''}`} onClick={handleClick} onMouseEnter={props.onHover}>
            <td className='quicksearch-result-icon'>{icon && <Icon iconName={icon} />}</td>
            <td>{normalizeName()}</td>
        </tr>
    );
}


export default HomeSearchBar;