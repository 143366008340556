import React, {useEffect, useState} from "react";
import {MODAL_STYLE} from "../../Constants";
import {getTheme, IButtonStyles, IconButton, Modal} from "@fluentui/react";
import GridColumnEdit from "./GridColumnEdit";
import {useGetSampleGridDataQuery} from "../../store/Api";
import {forEach} from "lodash";

interface IGridColumnEditModalProps {
    setName: string;
    isOpen: boolean;
    onClose: () => void;
    onSave: (columns: string[], assignment?: string) => void;
}
const theme = getTheme();
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const GridColumnEditModal: React.FC<IGridColumnEditModalProps> = (props) => {
    const gridSampleData = useGetSampleGridDataQuery(props.setName, {skip: !props.setName || props.setName === ''})
    const [sampleData, setSampleData] = useState<any[]>([])

    useEffect(() => {
        if(gridSampleData.data){
            const data: {[key: string]: string} = {}
            forEach(gridSampleData.data, (column, key) => {
                data[column.fieldIdentifier] = column.value;
            });
            setSampleData([data, data, data, data, data])
        }
    }, [gridSampleData.data]);
    return(
        <Modal

            isOpen={props.isOpen}
            onDismiss={() => props.onClose}
            containerClassName={MODAL_STYLE.container}
            styles={{
                main: {
                    width: '80%',
                    minWidth: '40em',
                    overflowX: 'scroll'
                }
            }}
        >
            <div className={MODAL_STYLE.header}>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={props.onClose}
                />
            </div>
            {sampleData &&
            <GridColumnEdit
                gridIdentifier={props.setName}
                testItems={sampleData}
                onSave={(columns, assignment) => {
                    props.onSave(columns, assignment)
                }}
            />}

        </Modal>);
}

export default GridColumnEditModal;