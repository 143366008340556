import React from 'react';
import { DefaultButton, Dropdown, IDropdownOption, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import {
    useGetStorefrontOrderQuery,
    useGetStorefrontOrderStatusesQuery,
    useProcessStorefrontOrderMutation,
    useUpdateStorefrontOrderStatusMutation
} from '../../../store/Api';
import { IsMutationSuccess } from '../../../logic/MutationTypeChecker';
import StorefrontLabel from '../StorefrontLabel';
import StorefrontOrderProductsTable from './StorefrontOrderProductsTable';
import StorefrontOrderBundlesTable from './StorefrontOrderBundlesTable';

interface IStorefrontOrderPanel {
    order?: string;
    onDismiss: () => void;
}

const StorefrontOrderPanel: React.FC<IStorefrontOrderPanel> = (props) => {
    const [processOrder] = useProcessStorefrontOrderMutation();
    const [processing, setProcessing] = React.useState<boolean>(false);
    const statuses = useGetStorefrontOrderStatusesQuery();
    const [statusOptions, setStatusOptions] = React.useState<{ key: string, text: string }[]>([]);
    const [updateOrderStatus] = useUpdateStorefrontOrderStatusMutation();
    const [status, setStatus] = React.useState<string | undefined>();
    const order = useGetStorefrontOrderQuery(props.order ?? "", {skip: !props.order || props.order === ''})

    React.useEffect(() => {
        if(order.data) {
            setStatus(order.data.status);
        }
    }, [order.data])

    React.useEffect(() => {
        if (statuses.data == null) return;
        setStatusOptions(Object.entries(statuses.data).map(([key,text]) => ({ key, text})));
    }, [statuses.data])

    const process = async () => {
        if (props.order == null) return;
        setProcessing(true);
        await processOrder({id: props.order});
        setProcessing(false);
    }

    const updateStatus = async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        const res = await updateOrderStatus({id: props.order ?? '', status: option?.key as string});
        if (IsMutationSuccess(res)) {
            setStatus(option?.key as string);
        }
    }

    return (
        <Panel
            isOpen={props.order != null}
            onDismiss={props.onDismiss}
            type={PanelType.medium}
            headerText={order?.data?.orderNumber ? `Order ${order?.data?.orderNumber.toString()}` : 'Loading...'}
            isFooterAtBottom={true}
            onRenderFooterContent={() => (
                <Stack horizontal tokens={{childrenGap:'1em'}}>
                    <PrimaryButton text={processing ? 'Processing' : 'Process'} onClick={process} disabled={processing} />
                    <DefaultButton text='Cancel' onClick={props.onDismiss} />
                </Stack>
            )}
            >
            {order?.data && <div>
                <p>
                    <span style={{fontWeight: 'bold'}}>Order Number: </span>
                    {order.data.orderNumber}
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>Storefront: </span>
                    {order.data.storefront && <StorefrontLabel storefrontId={order.data.storefront} />}
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>Email Address: </span>
                    {order.data.emailAddress}
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>Order Date: </span>
                    {order.data.createDate && new Date(order.data.createDate).toLocaleString()}
                </p>
                <Dropdown
                    label='Status'
                    options={statusOptions}
                    selectedKey={status}
                    onChange={updateStatus}
                    />
                <h3>Products</h3>
                <StorefrontOrderProductsTable products={order.data.products} /> 
                <h3>Bundles</h3>
                <StorefrontOrderBundlesTable bundles={order.data.bundles} />
            </div>}
        </Panel>
    );
}

export default StorefrontOrderPanel;