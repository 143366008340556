import React from 'react';
import {useGetItemDetailsQuery} from "../../store/Api";
import DetailsModal from "./DetailsModal";

interface IProductIdModalProps {
    catalog: string;
    productId: string;
    onDismiss: (id: string | undefined) => void;
}

const ProductIdModal: React.FC<IProductIdModalProps> = (props) => {
    const dismiss = () => {
        props.onDismiss(undefined);
    }
    
    return (<DetailsModal product={props.productId} catalog={props.catalog} open={true} onClose={dismiss} />);
}

export default ProductIdModal;