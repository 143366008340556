import React, {ReactElement, useLayoutEffect} from 'react';
import {Checkbox, Icon, Label, TooltipHost} from '@fluentui/react';

interface IToggleFieldProps {
    readonly?: boolean;
    disabled?: boolean;
    label?: string;
    checked?: boolean;
    onChange?: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
    toolTip?: string | ReactElement | ReactElement[] | undefined;
    width?: number;
}

const ToggleField: React.FC<IToggleFieldProps> = (props) => {
    const [isChecked, setIsChecked] = React.useState<boolean>(props.checked ?? false);
    useLayoutEffect(() => {
        setIsChecked(props.checked?? false);
    }, [props.checked]);
    const label = () => {
        if(props.toolTip){
            return(<TooltipHost content={props.toolTip}>
                <div style={{display: "inline-flex", width: props.width ? `${props.width}px` : undefined}}><Label><Icon iconName={'Info'} style={{paddingRight: '5px'}}/>{props.label}</Label> </div>
            </TooltipHost>)
        }
        return(<Label>{props.label}</Label>)
    }

    return( 
        <div className={'ac-details-field'} style={{width: props.width ? `${props.width}px` : undefined}}>
            {label()}
            <Checkbox
                styles={{text: {color: 'black', display: 'inline-flex'}}}
                disabled={props.readonly || props.disabled}
                checked={isChecked}
                onChange={props.onChange}
            />
        </div>
    )
}

export default ToggleField;