import {ILookupConditions} from "../models/LookupConditions";

export {}
declare global {
    interface String {
        appendIfDefined(checkString: string | undefined, stringToAppend: string): string;
    }
    interface Array<T> {
        appendIfDefined(checkString: string | undefined, stringToAppend: string): string[];
    }
}

String.prototype.appendIfDefined = function (checkString: string | undefined, stringToAppend: string): string {
    return (checkString !== undefined ? this + stringToAppend : this) as string;
};

Array.prototype.appendIfDefined = function (checkString: string | undefined, stringToAppend: string): string[] {
    if (checkString !== undefined && checkString !== '') {
        this.push(stringToAppend);
    }
    return this;
};

export function getQueryString(values: string[]): string {
    if(values.length === 0) return '';
    let queryString = '?'
    for(let i = 0; i < values.length; i++) {
        if(values[i] !== undefined) {
            queryString += values[i] + (i == values.length-1? '' : '&');
        }
    }
    return queryString;
}

export function getQueryStringFromConditions(lookupConditions?: ILookupConditions, additionalParameters?: string[]): string {
    if(!lookupConditions) return ''
    
    let lookUps = []
        .appendIfDefined(lookupConditions.searchBy, `searchBy=${lookupConditions.searchBy}`)
        .appendIfDefined(lookupConditions.searchTerm, `searchTerm=${lookupConditions.searchTerm}`)
        .appendIfDefined(lookupConditions.sortBy, `sortBy=${lookupConditions.sortBy}`)
        .appendIfDefined(lookupConditions.sortDirection, `sortDirection=${lookupConditions.sortDirection}`)
        .appendIfDefined(lookupConditions.page?.toString(), `page=${lookupConditions.page}`)
        .appendIfDefined(lookupConditions.pageSize?.toString(), `pageSize=${lookupConditions.pageSize}`)
    
    if(additionalParameters) {
        lookUps = lookUps.concat(additionalParameters)
    }
    const queryString = getQueryString(lookUps)
    return queryString
    
}
