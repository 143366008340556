import React from 'react';
import { useGetStorefrontOrdersQuery } from '../../store/Api';
import { ActivityItem, Icon } from '@fluentui/react';
import { IStorefrontOrder } from '../../models/Storefront';
import { ConvertToTimeAgo } from '../../logic/DateHelpers';

interface IStorefrontListProps {
    useDemoData?: boolean;
}

const DEMO_STOREFRONT_ORDERS: IStorefrontOrder[] = [
    {
        id: '1',
        emailAddress: 'bob@example.com',
        storefront: '',
        ipAddress: '',
        orderNumber: 38383,
        status: '',
        createDate: new Date(new Date().getTime() - 5 * 60 * 1000), // 5 minutes ago
        total: 0,
        subtotal: 0,
        tax: 0,
        shippingAddress: {},
        billingAddress: {},
        products: [],
        bundles: [],
        costTotal: 0
    },
    {
        id: '2',
        emailAddress: 'alice@example.com',
        storefront: '',
        ipAddress: '',
        orderNumber: 38384,
        status: '',
        createDate: new Date(new Date().getTime() - 10 * 60 * 1000), // 10 minutes ago
        total: 0,
        subtotal: 0,
        tax: 0,
        shippingAddress: {},
        billingAddress: {},
        products: [],
        bundles: [],
        costTotal: 0
        },
        {
        id: '3',
        emailAddress: 'charlie@example.com',
        storefront: '',
        ipAddress: '',
        orderNumber: 38385,
        status: '',
        createDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // 1 day ago
        total: 0,
        subtotal: 0,
        tax: 0,
        shippingAddress: {},
        billingAddress: {},
        products: [],
        bundles: [],
        costTotal: 0
        },
        {
        id: '4',
        emailAddress: 'dave@example.com',
        storefront: '',
        ipAddress: '',
        orderNumber: 38386,
        status: '',
        createDate: new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000), // 3 days ago
        total: 0,
        subtotal: 0,
        tax: 0,
        shippingAddress: {},
        billingAddress: {},
        products: [],
        bundles: [],
        costTotal: 0
        },
        {
        id: '5',
        emailAddress: 'eve@example.com',
        storefront: '',
        ipAddress: '',
        orderNumber: 38387,
        status: '',
        createDate: new Date(new Date().getTime() - 9 * 24 * 60 * 60 * 1000), // 9 days ago
        total: 0,
        subtotal: 0,
        tax: 0,
        shippingAddress: {},
        billingAddress: {},
        products: [],
        bundles: [],
        costTotal: 0
    }
];

const StorefrontList: React.FC<IStorefrontListProps> = (props) => {
    const getOrders = useGetStorefrontOrdersQuery({conditions:{page:1,pageSize:5,sortBy:'createDate',sortDirection:'desc'}});
    const [orders, setOrders] = React.useState<IStorefrontOrder[]>([]);

    React.useEffect(() => {
        if (props.useDemoData && (!getOrders.data?.items || getOrders.data.items.length === 0)) {
            setOrders(DEMO_STOREFRONT_ORDERS);
        } else {
            setOrders(getOrders.data?.items ?? []);
        }
    }, [getOrders.data, props.useDemoData]);

    if (orders.length === 0) return null;

    return (
        <div className={`home-list fade-in storefront-list`}>
            <h3>Storefront Activity</h3>
            {orders.map((order) => (
                <StorefrontOrderActivity order={order} key={order.id} />))}
        </div>
    );
}

const StorefrontOrderActivity: React.FC<{order: IStorefrontOrder}> = ({order}) => {
    const friendlyDate = ConvertToTimeAgo(new Date(order.createDate));
    return (
        <ActivityItem
            activityDescription={`${order.emailAddress} placed order ${order.orderNumber}`}
            activityIcon={<Icon iconName='Shop' />}
            timeStamp={friendlyDate}
            />
    );
}

export default StorefrontList;