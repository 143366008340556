import React, {useEffect} from 'react';
import { IStorefrontBundlePricing, IWorkspace } from '../../models/Workspace';
import {
    Checkbox,
    ComboBox,
    IComboBox,
    IComboBoxOption,
    PrimaryButton,
    SelectableOptionMenuItemType,
    TextField
} from '@fluentui/react';
import NumberField from '../Inputs/NumberField';
import {
    useGetStorefrontsQuery,
    useGetWorkspaceProductsQuery,
    usePutWorkspaceMutation
} from '../../store/Api';
import MarkupTypeSelection from '../Storefront/MarkupTypeSelection';
import { IProductImage } from '../../models/Search';
import {StorefrontBundleImages} from "../Storefront/StorefrontBundleImages";
import {cloneDeep} from "lodash";
import ToggleField from "../Common/ToggleField";
import DataField from "../Common/DataField";
import PanelWrapper from "../Common/PanelWrapper";

interface IStorefrontBundleModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    workspace?: IWorkspace;
}

const StorefrontBundleModal: React.FC<IStorefrontBundleModalProps> = (props) => {
    const [bundleName, setBundleName] = React.useState<string>('');
    const [bundleDescription, setBundleDescription] = React.useState<string>('');
    const [bundleImageUrl, setBundleImageUrl] = React.useState<string>('');
    const [bundleMinimumPrice, setBundleMinimumPrice] = React.useState<number>();
    const [selectedStorefronts, setSelectedStorefronts] = React.useState<string[]>([]);
    const [pricing, setPricing] = React.useState<IStorefrontBundlePricing[]>([]);
    const [productImages, setProductImages] = React.useState<IProductImage[]>([]);
    const [hideItemDetails, setHideItemDetails] = React.useState<boolean>();
    const [descriptionSourceItems, setDescriptionSourceItems] = React.useState<string[]>([]);
    const storefronts = useGetStorefrontsQuery();
    const [saveWorkspace] = usePutWorkspaceMutation();
    const products = useGetWorkspaceProductsQuery(props.workspace?.id ?? "", {skip: !props.workspace?.id || props.workspace.id == ''});

    React.useEffect(() => {
        if (props.workspace == null) return;
        setBundleName(props.workspace.storefrontBundleDetails?.name ?? '');
        setBundleDescription(props.workspace.storefrontBundleDetails?.description ?? '');
        setBundleImageUrl(props.workspace.storefrontBundleDetails?.imageUrl ?? '');
        setBundleMinimumPrice(props.workspace.storefrontBundleDetails?.minimumPrice);
        setSelectedStorefronts(props.workspace.storefrontBundleDetails?.storefronts ?? []);
        setPricing(props.workspace.storefrontBundleDetails?.pricing ?? []);
        setHideItemDetails(props.workspace.storefrontBundleDetails?.hideLineItemDetails?? false);
        setDescriptionSourceItems(props.workspace.storefrontBundleDetails?.descriptionSourceItems?? []);
    }, [props.workspace?.storefrontBundleDetails]);

    useEffect(() => {
        if(products.data) {
            const images: IProductImage[] = [];
            products.data?.forEach(i => {
                if(i.product?.image) {
                    const productImage: IProductImage = {
                        name: "Product Image",
                        url: i.product.image,
                        type: "Product",
                        id: i.product.id,
                        isCustom: true
                    };
                    images.push(productImage);
                }
            })

            setProductImages([...images]);
        }
    }, [products.data]);

    const save = async () => {
        // Save the storefront bundle
        if (props.workspace == null) return;
        await saveWorkspace({
            id: props.workspace.id,
            storefrontBundleDetails: {
                name: bundleName,
                description: bundleDescription,
                imageUrl: bundleImageUrl,
                minimumPrice: bundleMinimumPrice,
                storefronts: selectedStorefronts,
                pricing: pricing,
                descriptionSourceItems: descriptionSourceItems,
                hideLineItemDetails: hideItemDetails
            }
        });
        props.onDismiss();
    }
    
    const getValueLabel = (markupType?: string) => {
        if(markupType === 'PERCENT') {
            return 'Percentage';
        }
        else if(markupType === 'FIXED') {
            return 'Amount';
        }
        else if(markupType === 'GROSS_MARGIN') {
            return 'Gross Margin Points';
        }
        else{
            return 'Value'
        }
    }
    
    const options = products.data?.map((product) => ({key: product.id, text: product?.normalized?.name.substring(0,50) ?? ""})) ?? []

    const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string,
    ): void => {

        const selected = option?.selected;
        const currentSelectedOptionKeys = descriptionSourceItems.filter(key => key !== 'selectAll');
        const selectAllState = currentSelectedOptionKeys.length === options.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setDescriptionSourceItems([])
                    : setDescriptionSourceItems(['selectAll', ...options.map(o => o.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === options.length) {
                    updatedKeys.push('selectAll');
                }
                setDescriptionSourceItems(updatedKeys);
            }
        }
        
    };
    
    const footerButtons = () =>[
        <PrimaryButton
            text='Save'
            onClick={save}
            style={{width:'90%'}}
        />
    ]
    return (
        <PanelWrapper
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            title='Storefront Bundle'
            footerButtons={footerButtons()}
            //onRenderFooter={footer}
            isFooterAtBottom={true}
            styles={{
                root: {
                    zIndex: 10,
                    marginTop: '48px',
                }
            }}
        >
            <div>
                <h4>Details</h4>
                <DataField
                    toolTip={'This defines the name of the bundle as it will appear in the storefront'}
                    label='Name'
                    value={bundleName}
                    onChange={(e, newValue) => setBundleName(newValue ?? '')}
                    placeholder={props.workspace?.name}
                    />
                <TextField
                    multiline={true}
                    rows={10}
                    label='Description'
                    value={bundleDescription}
                    onChange={(e, newValue) => {
                        setBundleDescription(newValue ?? '')
                    }}
                    />
                <NumberField
                    label='Minimum Price'
                    value={bundleMinimumPrice}
                    onChange={setBundleMinimumPrice}
                    prefix='$'
                    />
                <ToggleField
                    label={'Hide Item Details'}
                    toolTip={'When checked, bundle item descriptions, quantities, and images will not be displayed in the storefront'}
                    checked={hideItemDetails}
                    onChange={(e, v) => setHideItemDetails(v)}
                />

                <ComboBox 
                    label='Description Source Items'
                    selectedKey={descriptionSourceItems}
                    onChange={onChange}
                    options={options}
                    multiSelect={true}
                />
                
                <StorefrontBundleImages images={productImages}
                    selected={bundleImageUrl}
                    onSelect={(img) => setBundleImageUrl(img)}
                />
                
                {storefronts.data?.items?.map((storefront) => (
                    <div key={storefront.id} style={{marginBottom: '1em'}}>
                        <Checkbox
                            styles={{label: { fontWeight: 'bold' }}}
                            label={storefront.name}
                            checked={selectedStorefronts.includes(storefront.id ?? '')}
                            onChange={(e, checked) => {
                                if (checked) {
                                    setSelectedStorefronts([...selectedStorefronts, storefront.id ?? '']);
                                } else {
                                    const filtered = selectedStorefronts.filter((id) => id !== storefront.id) ?? []
                                    setSelectedStorefronts([...filtered]);
                                }
                            }}
                            />
                            {selectedStorefronts.includes(storefront.id ?? '') && 
                            <div>
                                <MarkupTypeSelection
                                    label='Markup Type'
                                    value={pricing.find((p) => p.storefront === storefront.id)?.markupType}
                                    onChange={(markupType) => {

                                        const foundIndex = pricing.findIndex(pr => pr.storefront === storefront.id);
                                        if (foundIndex >= 0) {
                                            const updatedPricing = cloneDeep(pricing)
                                            updatedPricing[foundIndex].markupType = markupType;
                                            setPricing([...updatedPricing]);
                                        }
                                        else{
                                            setPricing([...pricing, {storefront: storefront.id?? '', markupType}]);
                                        }
                                    }}
                                />
                                <NumberField
                                    label={getValueLabel(pricing.find((p) => p.storefront === storefront.id)?.markupType)}
                                    value={pricing.find((p) => p.storefront === storefront.id)?.markup}
                                    onChange={(markup) => {

                                        const foundIndex = pricing.findIndex(pr => pr.storefront === storefront.id);
                                        if (foundIndex >= 0) {
                                            const updatedPricing = cloneDeep(pricing)
                                            updatedPricing[foundIndex].markup = markup;
                                            setPricing([...updatedPricing]);
                                        }
                                        else{
                                            setPricing([...pricing, {storefront: storefront.id?? '', markup}]);
                                        }
                                    }}
                                    />
                            </div>}
                    </div>
                ))}
            </div>
        </PanelWrapper>
    );
}

export default StorefrontBundleModal;