import React, {useEffect, useState} from 'react';
import {ISavedSearchStorefrontDetails} from '../../models/Search';
import {Checkbox, Icon, TooltipHost} from '@fluentui/react';
import NumberField from '../Inputs/NumberField';
import MarkupTypeSelection from '../Storefront/MarkupTypeSelection';
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {useLazyGetSavedSearchOnStorefrontCountQuery} from "../../store/Api";


interface ISavedSearchStorefrontEntryDetailsProps {
    storefrontId?: string;
    storefrontName?: string
    storefrontEntry?: ISavedSearchStorefrontDetails;
    included?: boolean;
    onIncludedChange?: (included: boolean) => void;
    onStorefrontEntryChange: (storefrontEntry: ISavedSearchStorefrontDetails) => void;
    reachedTotalStorefrontLimit?: boolean;
}

const DEFAULT_STOREFRONT_ENTRY: ISavedSearchStorefrontDetails = {
    storefrontId: '',
    markup: 0,
    markupType: '',
}

const SavedSearchStorefrontEntryDetails: React.FC<ISavedSearchStorefrontEntryDetailsProps> = (props) => {
    
    const [storefrontEntry, setStorefrontEntry] = useState<ISavedSearchStorefrontDetails | undefined>(props.storefrontEntry);

    useEffect(() => {
        if(props.storefrontEntry?.storefrontId) {
            if (storefrontEntry) setStorefrontEntry({...props.storefrontEntry});
        }
    }, [props.storefrontEntry]);
    
    const markupChange = (markup?:number) => {
        if (!props.storefrontId) return;
        if (!storefrontEntry) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefrontId: props.storefrontId, markup});
        else props.onStorefrontEntryChange({...storefrontEntry, markup});
    }
    const markupTypeChange = (markupType:string) => {
        if (!props.storefrontId) return;
        if (!storefrontEntry) {
            props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefrontId: props.storefrontId, markupType: markupType});
        }
        else props.onStorefrontEntryChange({...storefrontEntry, markupType});
    }
    const onIncludedChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
        if (props.onIncludedChange) props.onIncludedChange(checked ?? false);
    }
    
    const onMinimumPriceChange = (minimumPrice?:number) => {
        if (!props.storefrontId) return;
        if (!storefrontEntry) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefrontId: props.storefrontId, minimumPrice});
        else props.onStorefrontEntryChange({...storefrontEntry, minimumPrice});
    }
    const onMinimumCostChange = (minimumCost?:number) => {
        if (!props.storefrontId) return;
        if (!storefrontEntry) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefrontId: props.storefrontId, minimumCost: minimumCost});
        else props.onStorefrontEntryChange({...storefrontEntry, minimumCost});
    }
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const [perStorefrontCount] = useLazyGetSavedSearchOnStorefrontCountQuery();

    const [reachedLimit, setReachedLimit] = useState<boolean>(false);
    useEffect(() => {
        if(props.reachedTotalStorefrontLimit){
            setReachedLimit(true);
        }
        else if(org?.storefrontSavedSearchLimit && props.storefrontId) {
            perStorefrontCount({storefrontId: props.storefrontId}).unwrap().then((resp => {
                setReachedLimit(resp.count > org.storefrontSavedSearchLimit);
            }));
        }
        else{
            setReachedLimit(false);
        }
    }, []);
    if(!props.storefrontId || !props.storefrontName || !props.storefrontEntry){
        return <></>
    }
    return (
        <tr>
            <td>{reachedLimit &&
                <TooltipHost
                    content={'Storefront has reached a maximum number of products and/or saved searches.'}><Icon
                    iconName={'Warning'} color={'red'}/></TooltipHost>
            }
            </td>
            <td style={{minWidth: '200px'}}>{props.storefrontName}</td>
            <td>
                <Checkbox
                    checked={props.included ?? false}
                    onChange={onIncludedChange}
                />
            </td>
            <td>
                <NumberField
                    value={storefrontEntry?.markup}
                    onChange={markupChange}
                    disabled={reachedLimit}
                />
            </td>
            <td>
                <MarkupTypeSelection
                    value={storefrontEntry?.markupType}
                    onChange={markupTypeChange}
                    disabled={reachedLimit}
                />
            </td>
            <td>
                <NumberField
                    value={storefrontEntry?.minimumPrice}
                    onChange={onMinimumPriceChange}
                    prefix='$'
                    disabled={reachedLimit}
                />
            </td>
            <td>
                <NumberField
                    value={storefrontEntry?.minimumCost}
                    onChange={onMinimumCostChange}
                    prefix='$'
                    disabled={reachedLimit}
                />
            </td>
        </tr>
    );
}

export default SavedSearchStorefrontEntryDetails;