import React, {useEffect, useState} from 'react';
import {
    ContextualMenu, Dropdown,
    Icon,
    IContextualMenuItem, 
    Label,
    MessageBar,
    MessageBarType,
    TextField, TooltipHost
} from "@fluentui/react";

interface IFilterableSortableColumnHeaderProps {
    label: string;
    field: string;
    filterValue?: string;
    sortedDescending?: boolean;
    sortable?: boolean;
    onSort?: (column? :string) => void;
    onFilterApply?: (filter?: string) => void;
    filterable?: boolean;
    filterMessageCondition?: (input: string) => boolean;
    filterMessage?: string;
    filterOptions?: {key: string, text: string}[] | undefined;
    tooltip?: string
}

const FilterableSortableColumnHeader: React.FC<IFilterableSortableColumnHeaderProps> = (props) => {
    const divRef = React.useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const [filterWarningMessage, setFilterWarningMessage] = useState<string>()
    const [options, setOptions] = React.useState<{key: string, text: string}[] | undefined>()
    const [filterValue, setFilterValue] = React.useState<string>()
    useEffect(() => {
        if(props.filterValue){
            setFilterValue(() => (props.filterValue))
        }
    }, [props.filterValue]);
    useEffect(() => {
        if(props.filterOptions){
            const options = [{key: '', text: '[No Filter]'}, ...props.filterOptions];
            setOptions(options);
        }
    }, [props.filterOptions])
    const label = () => {
        if (props.tooltip) {
            return (<TooltipHost content={props.tooltip}>
                <div style={{display: "inline-flex"}}>
                    <Label>
                        <Icon iconName={'Info'} style={{paddingRight: '5px'}}/>
                        {props.label}
                    </Label>
                </div>
            </TooltipHost>)
        }
        return (<Label>{props.label}</Label>)
    }
    
    
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'filter',
            onRender: () => (
                <div style={{padding: '1em'}}>
                    {filterWarningMessage &&
                        <MessageBar messageBarType={MessageBarType.blocked}>{filterWarningMessage}</MessageBar>}
                        {options ? 
                        <Dropdown
                            options={options}
                            selectedKey={props.filterValue}
                            onChange={(ev, val) => {
                                setFilterValue(val?.key as string)
                                setShowMenu(false)
                                props?.onFilterApply?.(val?.key as string)

                            }}
                        />
                            : 
                        <TextField
                            placeholder={'filter'}
                            defaultValue={filterValue}
                            onBlur={(ev) => {if(ev.target.value && props.onFilterApply){
                                  if(props?.filterMessageCondition &&!props.filterMessageCondition(ev.currentTarget.value) && props.filterMessage){
                                    setFilterWarningMessage(props.filterMessage)
                                    return
                                }
                                props.onFilterApply(ev.target.value)
                                setFilterValue(() => (ev.target.value))
                                setFilterWarningMessage('')
                            }}}
                            onKeyDown={(ev) =>{

                                if (ev.key === 'Enter' && props.onFilterApply) {
                                    if(props?.filterMessageCondition &&!props.filterMessageCondition(ev.currentTarget.value) && props.filterMessage){
                                        setFilterWarningMessage(props.filterMessage)
                                        return
                                    }
                                    setFilterValue(() => (ev.currentTarget.value))
                                    setShowMenu(false)
                                    props.onFilterApply(ev.currentTarget.value)
                                    setFilterWarningMessage('')
                                }
                            }}/>}
                </div>
            )
        }
    ];

    return (<div style={{display: 'inline-flex'}} ref={divRef}>{props.sortable &&
        <div className={"ac-sortable-column-header"} onClick={() => {if(props.onSort)props.onSort(props.field)}}>
            <Icon
                iconName={ (props.sortedDescending == undefined ? 'Sort': (props.sortedDescending  ? 'SortDown': 'SortUp'))}
                className={"ac-sortable-vendor-header-icon"}
                style={{paddingRight: '1px'}}
            />
        </div>}
        {label()}
        {props.filterable &&
            <div className={"ac-sortable-column-header"} onClick={() => () => setShowMenu(true)} style={{paddingLeft: '8px'}} >
                <Icon
                    iconName={ (props.filterValue && props.filterValue != '' ? 'FilterSolid' : 'Filter')}
                    className={"ac-sortable-vendor-header-icon"}
                    style={{paddingLeft: '5px'}}
                    onClick={() => {
                        if(showMenu){
                            setShowMenu(false)
                        }
                        else{setShowMenu(true)}
                    }}
                />
            </div>
        }
        <ContextualMenu items={menuItems} hidden={!showMenu} target={divRef} onDismiss={() => setShowMenu(false)}/>
    </div>);
}

export default FilterableSortableColumnHeader;