import {
    CommandBar,
    getTheme,
    ICommandBarItemProps,
    PanelType,
    PrimaryButton,
    SearchBox,
} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import {
    useGetRecentWorkspaceRefsQuery,
    useGetWorkspaceSearchViewsQuery,
    useImportWorkspaceProductsFromTemplateMutation
} from "../../store/Api";
import WorkspaceDeleteModal from "./WorkspaceDeleteModal";
import {setPunchoutWorkspace} from "../../store/settingsSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {useHistory} from "react-router-dom";

import PanelGridComposer from "../GridComposer/PanelGridComposer";
import {GridSelectionMode, IColumnDetails} from "../../models/GridConfiguration";
import {IWorkspace} from "../../models/Workspace";

interface IWorkspaceSearchModalProps {
    displayModal: boolean;
    closer: () => void;
}
const WorkspaceSearchModal: React.FC<IWorkspaceSearchModalProps> = props => {
    const isPunchoutWorkspace = window.location.pathname.toLowerCase().indexOf('punchout/workspace') !== -1;
    
    const getRecentWorkspaces = useGetRecentWorkspaceRefsQuery();
    const [selectedWorkspace, setSelectedWorkspace] = useState<string | undefined>();
    const [workspaceTypeFilter, setWorkspaceTypeFilter] = useState<string>("All")
    const [searchText, setSearchText] = useState<string>("")
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)
    const [filter, setFilter] = useState<string>()
    const [filterBy, setFilterBy] = useState<string>()
    const [sortBy, setSortBy] = useState<string>()
    const [sortDirection, setSortDirection] = useState<string>('desc')
    const workspaces = useGetWorkspaceSearchViewsQuery({filter: workspaceTypeFilter, searchText: searchText, conditions: {page: page, pageSize: pageSize, sortBy: sortBy, sortDirection: (sortBy? sortDirection : undefined), searchBy: filterBy, searchTerm: filter}});
    const [importProductsFromWorkspace] = useImportWorkspaceProductsFromTemplateMutation()
    const history = useHistory();
    
    const punchoutWorkspaceId = useSelector((state: RootState) => state.settings.punchoutWorkspace);
    const [deleteWorkspaceId, setDeleteWorkspaceId] = useState<string>()
    const [deleteWorkspaceModalOpen, setDeleteWorkspaceModalOpen] = React.useState(false);
    const [value] = useState<string | undefined>()
    const theme = getTheme();

    useEffect(() => {
        setPage(1)
    }, [searchText, workspaceTypeFilter]);

    useEffect(() => {
        if(getRecentWorkspaces.data){
            setSelectedWorkspace(getRecentWorkspaces.data[0].id)
        }
    }, [getRecentWorkspaces.data]);

    const commandItems: ICommandBarItemProps[] = [
        {
            key: 'allFilter',
            text: 'Active & Templates',
            onClick: () => setWorkspaceTypeFilter('All'),
            buttonStyles: {
                root: {
                    backgroundColor: (workspaceTypeFilter == 'All') ? '#ddd' : 'white'
                }
            },
        },
        {
            key: 'activeFilter',
            text: 'Active',
            onClick: () => setWorkspaceTypeFilter("Active"),
            buttonStyles: {
                root: {
                    backgroundColor: (workspaceTypeFilter == 'Active') ? '#ddd' : 'white'
                }
            },
        },
        {
            key: 'templatesFilter',
            text: 'Templates',
            onClick: () => setWorkspaceTypeFilter("Templates"),
            buttonStyles: {
                root: {
                    backgroundColor: (workspaceTypeFilter == 'Templates') ? '#ddd' : 'white'
                }
            },
        },
        {
            key: 'bundleFilter',
            text: 'Storefront Bundles',
            onClick: () => setWorkspaceTypeFilter("Bundles"),
            buttonStyles: {
                root: {
                    backgroundColor: (workspaceTypeFilter == 'Bundles') ? '#ddd' : 'white'
                }
            },
        }
        ]
    const rightCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: '',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {workspaces.refetch(); workspaces.refetch(); }
        }
    ]
    
    const header = () => {
        return (
        <React.Fragment>
            <CommandBar items={commandItems} farItems={rightCommands}/>
            <br/>
            <SearchBox
                defaultValue={searchText ?? undefined}
                placeholder={"Search Workspace Name"}
                value={value}
                onChange={(e, d) => {setSearchText(d ?? '');
                }}
            />
        </React.Fragment>)
    }
    const buttonColumns: IColumnDetails[] = [
        {
            index: 0,
            identifier: 'Select',
            label: '',
            type: '',
            component:(ws: IWorkspace) => <PrimaryButton
                //disabled={selectedWorkspace == key.id} 
                onClick={() => {
                    setSelectedWorkspace(ws.id);

                    const newPath = isPunchoutWorkspace ? `/punchout/workspace/${ws.id}` : `/workspace/${ws.id}`;
                    history.replace(newPath);
                    props.closer()
                }
                }>Open</PrimaryButton>,
            required: true,
        },
        {
            identifier: 'Import',
            label: '',
            type: '',
            component:(a: IWorkspace) => selectedWorkspace && a.isTemplate && a.id != selectedWorkspace  && !isPunchoutWorkspace?
                <PrimaryButton iconProps={{iconName: 'WebTemplate'}} onClick={() => {
                    {
                        importProductsFromWorkspace({sourceId: a.id, destinationId: selectedWorkspace ?? ""})
                    }
                }}>Import</PrimaryButton> : <></>,
            required: true,
        },
        {
            identifier: 'Delete',
            label: '',
            type: '',
            component:(a: IWorkspace) => isPunchoutWorkspace ? <></> :
                <PrimaryButton styles={{root: { backgroundColor: theme.palette.red, color: theme.palette.white }, rootFocused: { backgroundColor: theme.palette.redDark, color: theme.palette.white }, rootHovered: { backgroundColor: theme.palette.redDark, color: theme.palette.white },}}
                               iconProps={{iconName: 'Delete'}}
                               onClick={() => {{
                                   setDeleteWorkspaceId(a.id);setDeleteWorkspaceModalOpen(true)
                               }
                               }}>
                </PrimaryButton>,
            required: true,
        }
    ]
    return (
        <div>
            <PanelGridComposer
                page={page}
                pageSize={pageSize}
                sortBy={sortBy}
                sortDirection={sortDirection}
                overrideColumns={buttonColumns}
                canChangePageSize={true}
                onDismiss={props.closer}
                isOpen={props.displayModal}
                panelTitle={'Search Workspaces'} 
                columnSetName={'WorkspaceSearchView'} 
                data={workspaces.data ?? {}} 
                loading={workspaces.isFetching || workspaces.isLoading} 
                onPageChange={setPage} 
                onPageSizeChange={setPageSize} 
                selectionMode={GridSelectionMode.none}
                onFilter={(filter, column) => {
                    setFilter(() => filter)
                    setFilterBy(() => column)
                }}
                onSort={(column, direction) => {
                    setSortBy(() => column)
                    setSortDirection(() => direction ?? 'desc')
                }}
                headerBottom={header()}
                type={PanelType.large}
            />
            <WorkspaceDeleteModal
                onDeleteWorkspace={() => {
                    if(selectedWorkspace != "" && selectedWorkspace == punchoutWorkspaceId){
                        setPunchoutWorkspace(undefined);
                    }
                    const data = workspaces?.data?.items;
                    if (data === undefined) return;
                    const selectedWs = data[0].id == selectedWorkspace ? data[1] : data[0]
                    setSelectedWorkspace(selectedWs.id)
                }}
                selectedWorkspace={deleteWorkspaceId}
                displayModal={deleteWorkspaceModalOpen} 
                closer={() => setDeleteWorkspaceModalOpen(false)}
            />
        </div>

    );
}
export default WorkspaceSearchModal;