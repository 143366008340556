import { driver } from "driver.js";

export const WELCOME_TOUR_KEY = 'welcome';
export const OPTIONAL_STEP_STOREFRONT = 'storefront';

export const WelcomeTour = (onDestroy?: () => void, onComplete?: () => void, onDismiss?: () => void, enabledOptionalSteps?: string[]) => driver({
        showProgress: false,
        steps: [
            {
                popover: {
                    title: 'Welcome to Adaptive Catalog!',
                    description: 'This is your home screen. You can search for products, view your workspaces, and see your favorite products here.',
                }
            },
            {
                element: '.nav-menu',
                popover: {
                    title: 'Navigation Menu',
                    description: 'This is the primary navigation menu. You can access different parts of Adaptive Catalog from here.',
                }
            },
            {
                element: '.home-search-bar',
                popover: {
                    title: 'Quick Search',
                    description: 'Quickly find products or workspaces by typing in the search bar. Pressing enter will take you to the search page with your entered keywords.',
                }
            },
            {
                element: '.workspace-list',
                popover: {
                    title: 'Workspaces',
                    description: 'View your recent workspaces here. Click on a workspace to open it.',
                }
            },
            {
                element: '.favorites-list',
                popover: {
                    title: 'Favorites',
                    description: 'View your favorite products here. Click on a product to view more details. If you do not have any favorited products, this section will not show.',
                },
            },
            ...(enabledOptionalSteps?.find(i => i === OPTIONAL_STEP_STOREFRONT) ? [{
                element: '.storefront-list',
                popover: {
                    title: 'Storefront Activity',
                    description: 'View recent storefront activity here. If there has been no storefront activity in the last 30 days, this section will not show.',
                },
            }] : []),
            {
                element: '.user-icon',
                popover: {
                    title: 'User Menu',
                    description: 'Click here to access your user settings, log out, or switch organizations.',
                    onNextClick: onComplete,
                },
            }
        ],
        onCloseClick: onDismiss,
        onDestroyed: onDestroy, 
})