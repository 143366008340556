import React, {useEffect} from "react";
import {
    CommandBar,
    Dropdown,
    ICommandBarItemProps, 
    IStackTokens,
    Label, MessageBar, 
    MessageBarType,
    Spinner,
    Stack
} from "@fluentui/react";

import {
    useGetAvailableGridConfigurationsQuery, useGetGridAssignmentQuery,
    useGetGridConfigurationQuery,
    useSaveGridAssignmentMutation,
    useSaveGridConfigurationMutation,
} from "../../store/Api";
import {MODAL_STYLE} from "../../Constants";
import {IDropdownOption, IDropdownStyles} from "@fluentui/react/lib/Dropdown";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import DataGrid from "../DataGrid/DataGrid";
import {GridSelectionMode, IColumnDetails, IGridConfiguration} from "../../models/GridConfiguration";
import DataField from "../Common/DataField";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import ToggleField from "../Common/ToggleField";
import * as changeCase from "change-case"

interface IGridColumManagementProps {
    gridIdentifier: string;
    testItems: any[];
    onSave?: (columns: string[], assignment?: string) => void;
}
const GridColumnEdit: React.FC<IGridColumManagementProps> = (props) => {
    const user = useSelector((state: RootState) => state.auth.userName);
    const [saveGridAssignment] = useSaveGridAssignmentMutation();
    const [updateColumns] = useSaveGridConfigurationMutation();
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
    const [testColumnDetails, setTestColumnDetails] = React.useState<IColumnDetails[]>([]);
    const [availableGrids, setAvailableGrids] = React.useState<IGridConfiguration[]>([]);
    const [currentGrid, setCurrentGrid] = React.useState<IGridConfiguration>({} as IGridConfiguration);
    const [canEdit, setCanEdit] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [items, setItems] = React.useState<any[]>([]);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [selectedGrid, setSelectedGrid] = React.useState<string>();
    const [shouldAssign, setShouldAssign] = React.useState(true);

    const getColumns = useGetGridConfigurationQuery({gridIdentifier: props.gridIdentifier, gridIdHint: selectedGrid ?? ''}, {skip: (!props.gridIdentifier || props.gridIdentifier === '')})
    const getColumnSets = useGetAvailableGridConfigurationsQuery(props.gridIdentifier, {skip: (!props.gridIdentifier || props.gridIdentifier === '')});
    const getGridAssignment = useGetGridAssignmentQuery(props.gridIdentifier, {skip: (!props.gridIdentifier || props.gridIdentifier === '')});
    
    useEffect(() => {
        setItems(props.testItems);
    }, [props.testItems]);
    
    useEffect(() => {
        setSelectedGrid(getGridAssignment?.data?.assignment);
    }, [getGridAssignment]);

    useEffect(() => {
        if(getColumns.data){
            setCanEdit(getColumns.data?.username === user);
            setCurrentGrid(getColumns.data)
        }
        if(getColumns.data?.visibleColumns) {
            setSelectedKeys(getColumns.data?.visibleColumns)
        }
    }, [getColumns.data]);
    
    useEffect(() => {
        if(getColumnSets.data){
            const data = getColumnSets.data ?? []
        setAvailableGrids(() => [...data])
        }
    }, [getColumnSets]);

    useEffect(() => {
        const columns: IColumnDetails[] = [];
        selectedKeys.forEach((key) => {
            const found = getColumns?.data?.columnDetails?.find(a => a.identifier === key)
            if(found){
                columns.push(found);
            }
        })
        setTestColumnDetails(() => [...columns])
    }, [selectedKeys]);
    
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: {width: 300,},
    };
    const onChange = (item: IDropdownOption | undefined): void => {
        if (item) {
            setSelectedKeys(
                item.selected
                    ? [...selectedKeys, item.key as string]
                    : selectedKeys.filter(key => key !== item.key)
            );
        } else {
            setSelectedKeys(getColumns?.data?.visibleColumns ?? []);
        }
    }
    
    const onResetColumns = () => {
        
        const defaultColumns: IColumnDetails[] = getColumns?.data?.columnDetails?.filter(a => a.columnDefault) ?? []

        setSelectedKeys(defaultColumns.map(a => a.identifier));
    }
    
    const getFriendlyNameFromIdentifier = () => {
        return changeCase.capitalCase(props.gridIdentifier);
    }
    const commands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Reset Columns',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {onResetColumns();}
        }
    ];
    
    const save = (isNew?: boolean, skipAssignment?: boolean) => {
        const grid = {...currentGrid}
        grid.source = currentGrid.id ?? "default"
        grid.gridIdentifier = props.gridIdentifier;
        grid.visibleColumns = selectedKeys;
        if(isNew){
            grid.id = undefined;
            grid.name = getSaveableGridName();
        }
        setSaving(true)
        updateColumns({
            gridIdentifier: props.gridIdentifier,
            grid: grid
        }).unwrap().then((res) => {
            setSaving(false)
            if(res.success) {
                if (!skipAssignment) {
                    saveGridAssignment({gridIdentifier: props.gridIdentifier, gridId: res.id})
                    props?.onSave?.(selectedKeys, res.id)
                }

                    props?.onSave?.(selectedKeys)
                setErrorMessage('')
            }
            setErrorMessage(res.message ?? 'Error saving grid');
        })

    }
    const stackTokens: IStackTokens = {childrenGap: 10};

    const sorter = ( object1: any, object2: any, field: string, direction: string) =>{
        if(direction === 'asc') {
            if ((object1[field] ?? "") < (object2[field] ?? ""))
                return -1;
            if ((object1[field] ?? "") > (object2[field] ?? ""))
                return 1;
            return 0;
        }
        else{
            if ((object1[field] ?? "") < (object2[field] ?? ""))
                return 1;
            if ((object1[field] ?? "") > (object2[field] ?? ""))
                return -1;
            return 0;            
        }
    }
    
    const getGridNameValue = () => {
        
        return currentGrid.name === 'Default' ? `${getFriendlyNameFromIdentifier()} ${user ?? ''}` : currentGrid.name;
    }
    const getSaveableGridName = () => {
        return getGridNameValue() == getColumns.data?.name ? `${getColumns.data?.name} - Copy` : getGridNameValue()
    }
    
    return (
        <div className="main-content">
            <div className={MODAL_STYLE.header}>
                <span>Customize {getFriendlyNameFromIdentifier()} Columns</span>
            </div>
            {getColumns.isLoading &&
                <div className={MODAL_STYLE.body}>
                    <Spinner label="Loading..." ariaLive="assertive" style={{margin: '5em'}}/>
                </div>
            }

            {!getColumns.isLoading &&
                <div className={MODAL_STYLE.body}>
                    <div>
                        <CommandBar items={commands} />
                        {availableGrids && availableGrids.length > 0 && 
                        <Dropdown
                            placeholder="Select Saved Column Set"
                            label="Select Saved Column Set"
                            selectedKey={selectedGrid ?? currentGrid?.id}
                            options={availableGrids?.map(a => ({
                                key: a.id ?? "",
                                text: a.name ?? "",
                            })) ?? []}
                            styles={dropdownStyles}
                            onChange={(_e, selectedItem) => {
                                setSelectedGrid(selectedItem?.key as string);
                            }}
                        />
                        }
                        <div style={{width: '25%', minWidth: '250px'}}>
                            <DataField
                                label="Column Set Name"
                                value={getGridNameValue()}
                                onChange={(e, v) => {setCurrentGrid({...currentGrid, name: v})}}
                            />
                        </div>
                        <ToggleField
                            label={"Shared"}
                            checked={currentGrid?.shared}
                            onChange={(e, v) => {setCurrentGrid({...currentGrid, shared: v})}}
                        />
                        <Dropdown
                            placeholder="Select options"
                            label="Select Columns"
                            selectedKeys={selectedKeys}
                            multiSelect
                            options={currentGrid?.columnDetails?.map(a => ({
                                key: a.identifier,
                                text: a.label,
                            })) ?? []}
                            styles={dropdownStyles}
                            onChange={(_e, selectedItem) => onChange(selectedItem)}
                        />


                        <Label style={{marginTop: '1em'}}>Arrange Columns</Label>
                        {testColumnDetails && testColumnDetails.length > 0 &&
                        <DataGrid 
                            items={items} 
                            selectionMode={GridSelectionMode.multiple} 
                            loading={false} 
                            onFilterChange={(value, field) => {
                                
                                if(field) {
                                    const normalizedField = field.replace('.keyword', '') ?? field
                                    const filteredItems = props.testItems.filter(item => item[normalizedField] === value);
                                    setItems(() => [...filteredItems])
                                }
                            }} 
                            onSortChange={(field, direction) =>{
                                if(field) {
                                    const sortedItems = items.sort((a, b) => sorter(a, b, field, direction ?? 'asc'));
                                    setItems(() => [...sortedItems])
                                }
                            }}
                            overrideColumns={testColumnDetails}
                            canDrag={true}
                            onColumnChange={(details) => {
                                setSelectedKeys(details.map(a => a.identifier))
                                setCurrentGrid((initial) => ({...initial, visibleColumns: details.map(a => a.identifier)}))}
                        }
                            isCustomizeModal={true}
                        />}

                    </div>
                    <div className={MODAL_STYLE.body}>
                        <Stack horizontal tokens={stackTokens} style={{paddingTop: '10px'}} horizontalAlign='end'>
                            <ToggleField label={'Assign Grid'} onChange={(e, v) => setShouldAssign(v ?? false)} checked={shouldAssign}/>
                            {canEdit && <PrimaryButton text="Save" disabled={saving} onClick={() => save(false, !shouldAssign)} iconProps={{iconName: 'Save'}}/> }
                            <PrimaryButton text="Save as Copy" onClick={() => save(true, !shouldAssign)} disabled={saving} iconProps={{iconName: 'SaveAs'}}/>
                        </Stack>
                        {errorMessage && <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>}
                    </div>
                </div>
            }
        </div>
    );
}
export default GridColumnEdit;
