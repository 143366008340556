import React from 'react';
import {IWorkspaceItem} from "../../models/Workspace";
import {useGetAllVendorSettingsQuery, usePutWorkspaceItemDetailsMutation} from "../../store/Api";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import { renderVendorNameFromAllSettings } from '../../logic/Product';

interface IVendorSourceSelectionProps {
    item: IWorkspaceItem;
    isExample?: boolean;
}

const VendorSourceSelection: React.FC<IVendorSourceSelectionProps> = (props) => {
    const [detailsChange] = usePutWorkspaceItemDetailsMutation();
    const vendorSettings = useGetAllVendorSettingsQuery();

    return <Dropdown
        selectedKey={props.item.details?.source}
        options={[
            {key: 'LowestInStock', text: 'Lowest Price In Stock'},
            {key: 'LowestPrice', text: 'Lowest Price'},
            ...(props.item.product?.vendors?.map(vendor => (
                {
                    key: vendor.vendorName ?? '',
                    text: `${renderVendorNameFromAllSettings(vendor.vendorName, vendorSettings.data)}${vendor.inStock ? '' : ' (Out of Stock)'} - ${(vendor?.cost ?? 0).toFixed(2)}`
                }
            )) ?? [])
        ]}
        onChange={(e,d) => {
            if (d && !props.isExample) {
                detailsChange({
                    workspace: props.item.workspace,
                    product: props.item.id,
                    details: {
                        ...props.item.details,
                        discountedCost: undefined,
                        source: (d.key ?? '') as string,
                    }
                })
            }
        }}
    />
}

export default VendorSourceSelection;