import React from 'react';
import { usePostFeatureRequestMutation } from '../../store/Api';
import { IsMutationSuccess } from '../../logic/MutationTypeChecker';
import { IActionResponse } from '../../dal/BaseDAL';
import { DefaultButton, Panel, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { tracker } from '../../store/store';

interface IFeatureRequestPanelProps {
    isOpen: boolean;
    onClose: () => void;
    tags?: string[];
    description?: string;
    thanksMessage?: string;
    title?: string;
}

const FeatureRequestPanel: React.FC<IFeatureRequestPanelProps> = (props) => {
    const [createFeatureRequest] = usePostFeatureRequestMutation();
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>();

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [attachments, setAttachments] = React.useState<string[]>([]);

    const submit = async () => {
        setSubmitting(true);
        const sessionUrl = tracker.isActive() ? `Sesssion: https://assist.adaptivecatalog.com/1/session/${tracker?.getSessionID()}` : '';
        const augmentedDescription = `${description}
        ---
        URL: ${window.location.href}
        ${sessionUrl}`;
        const result = await createFeatureRequest({title, description: augmentedDescription, attachments});
        if (IsMutationSuccess<IActionResponse>(result)) {
            if (result.data.success) {
                setSuccess(true);
                setTitle('');
                setDescription('');
                setAttachments([]);
            } else {
                setError(result.data.message ?? 'An error occurred');
            }
        } else {
            setError('An unknown error occurred');
        }
        setSubmitting(false);
    }

    const close = () => {
        setTitle('');
        setDescription('');
        setAttachments([]);
        setSuccess(false);
        setError(undefined);
        props.onClose();
    }
    
    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={close}
            headerText={props.title ?? 'Feature Request'}
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
            onRenderFooterContent={() => <Stack horizontal tokens={{childrenGap: '1em'}}>
                <PrimaryButton onClick={submit} disabled={submitting || success}>Submit</PrimaryButton>
                <DefaultButton onClick={close}>Cancel</DefaultButton>
            </Stack>}
        >
            {props.description && <p>{props.description}</p>}
            {success && <p>{props.thanksMessage ?? 'Thank you for submitting your feature request!'}</p>}
            {error && <div>Error: {error}</div>}
            {!success && <Stack tokens={{childrenGap: '1em'}}>
                <TextField label="Title" value={title} onChange={(e, newValue) => setTitle(newValue ?? '')} />
                <TextField label="Description" multiline rows={6} value={description} onChange={(e, newValue) => setDescription(newValue ?? '')} />
            </Stack>}
        </Panel>

    );
}

export default FeatureRequestPanel;