import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { Dropdown, IDropdownOption, Label } from '@fluentui/react';

interface IStorefrontDomainDropdownProps {
    value?: string;
    onChange: (value: string) => void;
    emptyLabel?: string;
    label?: string;
}

const StorefrontDomainDropdown: React.FC<IStorefrontDomainDropdownProps> = (props) => {
    const organization = useSelector((state: RootState) => state.settings.selectedOrganization);
    const [domains, setDomains] = React.useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        if (organization) {
            const availableDomains = organization.storefrontDomains?.map(domain => ({key: domain, text: domain})) ??
                [{key: organization.storefrontDomain, text: organization.storefrontDomain}]
            setDomains([
                { key: '', text: props.emptyLabel ?? 'Default' },
                ...availableDomains,
            ]);
        }
    }, [organization]);

    if (domains.length <= 2) return null; // 2 because of the default option

    return <>
        <Label>{props.label}</Label>
        <Dropdown
            selectedKey={props.value}
            onChange={(e, item) => props.onChange(item?.key as string)}
            options={domains}
            placeholder={props.emptyLabel}
        />
    </>
}

export default StorefrontDomainDropdown;