import React, {useState} from 'react';
import {
    useGetStorefrontOrdersQuery,
    useGetStorefrontOrderStatusesQuery,
    useGetStorefrontsQuery
} from '../../../store/Api';
import {IStorefrontOrder} from '../../../models/Storefront';
import StorefrontOrderPanel from './StorefrontOrderPanel';
import GridComposer from "../../GridComposer/GridComposer";
import {GridSelectionMode, IColumnDetails} from "../../../models/GridConfiguration";
import {PrimaryButton} from "@fluentui/react";

const StorefrontOrdersTable: React.FC = () => {
    
    const [selectedOrder, setSelectedOrder] = React.useState<string | undefined>(undefined);
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)
    const [filter, setFilter] = useState<string>()
    const [filterField, setFilterField] = useState<string>()
    const [sortBy, setSortBy] = useState<string>()
    const [sortDirection, setSortDirection] = useState<string>()
    const orders = useGetStorefrontOrdersQuery({ conditions:{page, pageSize, searchTerm: filter, searchBy: filterField, sortBy, sortDirection}})
    const possibleStorefronts = useGetStorefrontsQuery();
    const possibleStorefrontStatuses = useGetStorefrontOrderStatusesQuery();

    const getCustomColumns = () => {
        const columnDetails: IColumnDetails[] = [
            {
                index: 0,
                identifier: 'Details',
                label: '',
                type: '',
                component:() => <PrimaryButton label={'Details'}>Details</PrimaryButton>,
                onClick: (order: IStorefrontOrder) => {setSelectedOrder(() => order.id)},
                required: true,
            },
            {
                identifier: 'storefront.keyword',
                label: 'Storefront',
                type: '',
                sortable: true,
                filterable: true,
                component:(order: IStorefrontOrder) => <div>{possibleStorefronts.data?.items?.find((a) => a.id === order.storefront)?.name}</div>,
                customFilterOptions: () => {
                    return possibleStorefronts.data?.items?.map((a) => ({key: a.id ?? "", text: a.name ?? ""}));
                },
                tooltip: 'Sort on Storefront is not alphabetical'
            },
            {
                identifier: 'status.keyword',
                label: 'Status',
                type: '',
                sortable: true,
                filterable: true,
                customFilterOptions: () => {
                    if(possibleStorefrontStatuses?.data) {
                        return Object.entries(possibleStorefrontStatuses.data)?.map(([key, text]) => ({key, text}));
                    }
                },
                tooltip: 'Sort on Storefront is not alphabetical'
            }
        ];
        return columnDetails
    }

    return (<div style={{overflowX: 'auto'}}>
        <GridComposer
            data={orders.data ?? {}}
            onPageChange={(page) => setPage(() => page)}
            onPageSizeChange={(pageSize) => setPageSize(() => pageSize)}
            loading={orders.isLoading || orders.isFetching}
            selectionMode={GridSelectionMode.multiple} 
            columnSetName={'StorefrontOrder'} 
            filter={filter}
            filterField={filterField}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSort={(column, direction) => {
                setSortBy(() => column)
                setSortDirection(() => direction)
            }}
            onFilter={(filter, column) => {
                setFilter(() => filter)
                setFilterField(() => column)
            }}
            overrideColumns={getCustomColumns()}
            canDragColumns={true}
            canResizeColumns={true}
            canCustomizeColumns={true}
            canChangePageSize={true}
            canCreateCsv={true}
        />
        <StorefrontOrderPanel
            order={selectedOrder}
            onDismiss={() => setSelectedOrder(undefined)}
            />
    </div>
    );
}

export default StorefrontOrdersTable;