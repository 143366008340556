import React from "react";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import {IVendor} from "../../models/Search";
import {DetailsList, IColumn, SelectionMode} from "@fluentui/react";

interface IWarehouseTableProps {
    vendors?: IVendor[]; 
    product: INormalizedProduct;
    vendor?: string;
    showOutOfStock?: boolean;
}

export const WarehouseTable: React.FC<IWarehouseTableProps> = (props) => {
    const display = [];
    if (props.vendors) {
        return <>
            {props.vendors.map(vendor => (<>
                <WarehouseDetail key={`warehousedetail-${vendor.vendorName}-${props.product.id}`} vendor={vendor} showOutOfStock={props.showOutOfStock} />
            </>))}
        </>
    }
    if (props.product.vendors) {
        for(const vendor of props.product.vendors) {
            if (vendor.warehouses && vendor.warehouses.length > 0) {
                const detail = <WarehouseDetail key={`warehouse-${vendor.vendorName}-${props.product.id}`} vendor={vendor} showOutOfStock={props.showOutOfStock} />;
                display.push(detail);
            }
        }
    }
    if (display.length === 0) {
        return <p style={{fontStyle:'italic'}}>No warehouse data available</p>
    }
    return <>{display}</>
};

interface IWarehouseDetailProps {
    vendor: IVendor;
    showOutOfStock?: boolean;
}

const WarehouseDetail: React.FC<IWarehouseDetailProps> = (props) => {
    const columns: IColumn[] = [
        {
            key: "warehouse",
            name: "Warehouse",
            fieldName: "warehouse",
            minWidth: 150,
            // maxWidth: 50,
            targetWidthProportion: 1,
            isRowHeader: true,
        },
        {
            key: "onHand",
            name: "On Hand",
            fieldName: "onHand",
            minWidth: 100,
            maxWidth: 100,
        }
    ]

    if (props.vendor.warehouses == null || props.vendor.warehouses.length === 0) {
        return null;
    }

    return (
        <DetailsList
            selectionMode={SelectionMode.none}
            items={props.vendor.warehouses ?? []}
            columns={columns}
        />
    );
}