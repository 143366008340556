import React, {ReactElement} from 'react';
import {
    IPanelStyleProps,
    IPanelStyles,
    IStyleFunctionOrObject,
    mergeStyleSets,
    Panel,
    PanelType, Stack, StackItem
} from "@fluentui/react";

interface IPanelWrapperProps {
    isOpen?: boolean;
    onDismiss?: ((ev?: (React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined)) => void) | undefined;
    className?: string;
    isFooterAtBottom?: boolean;
    isAutoWidth?: boolean;
    customWidthPx?: number;
    title?: string;
    styles?: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles> | undefined;
    type?: PanelType | undefined;
    footerButtons?: ReactElement[] | undefined;
    footerTop?: ReactElement | undefined;
    headerBottom?: ReactElement | undefined;
    background?: string;
}


const PanelWrapper: React.FC<IPanelWrapperProps> = (props) => {
    const backgroundColor = (props.background ?? 'rgb(255, 255, 255)');

    const contentStyles = mergeStyleSets(props.styles, {
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: backgroundColor,
        },
        navigation: {
            backgroundColor: backgroundColor,
        },
        footerInner: {
            backgroundColor: backgroundColor,
        },
        content: {
            backgroundColor: backgroundColor,
        },
        commands: {
            backgroundColor: backgroundColor,
        },
    });
    
    const isLargePanel = () => {
        return (props.type === PanelType.large || props.type === PanelType.extraLarge || (props?.customWidthPx ?? 0) > 800 || props.isAutoWidth)
    }
    
    const footer = () => {
        if (props.footerButtons || props.footerTop) {
            return (
                <div className={isLargePanel() ? 'fixed-footer-large': 'fixed-footer'} style={{backgroundColor: backgroundColor}}>
                    {props.footerTop}
                    {props.footerButtons &&
                    
                    <Stack horizontal grow={100} tokens={{ childrenGap: 10 }}>
                        <div style={{width: '100%'}}>
                            <div style={{paddingLeft: '24px', display: (isLargePanel() ? 'inline-flex' : 'flex'), justifyContent: 'space-between'}}>
                            {props.footerButtons?.map((button, index) => {
                                return (
                                    <StackItem grow key={`${props.title}-footerbutton-${index}`} style={{paddingRight: (isLargePanel() ? '10px' : 0)}}>
                                        {button}
                                    </StackItem>
                                )
                            })}
                            </div>
                        </div>
                    </Stack>}
                </div>
            )
        }
        return null;
    }
    
    const header = () => {
        return(
        <div style={{width: '100%'}}>
            <div style={{paddingLeft: '24px'}}><h1>{props.title}</h1></div>
            {props.headerBottom}
        </div>
        )
    }
    return (
        <div className="panel">
            <Panel
                onOuterClick={() => {
                    console.log("swallowing modal close event")
                }}
                onRenderHeader={header}
                headerText={props.title}
                closeButtonAriaLabel="Close"
                styles={contentStyles}
                isOpen={props.isOpen}
                onDismiss={props.onDismiss}
                className={props.className}
                isFooterAtBottom={props.isFooterAtBottom}
                customWidth={props.customWidthPx ? `${props.customWidthPx}px` : undefined ?? props.isAutoWidth ? 'auto' : undefined}
                title={props.title}
                type={props.type}
                onRenderFooter={footer}
            >
                {props.children}
            </Panel>
        </div>
    );
}

export default PanelWrapper;