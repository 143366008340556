import React, {useEffect, useState} from 'react';
import {ContextualMenu, ContextualMenuItemType, Persona, PersonaSize} from "@fluentui/react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import api, {
    useClearExtensionsMutation,
    useClearSearchMutation,
    useClearWorkspaceCacheMutation,
    useSetSelectedOrganizationMutation
} from "../../store/Api";
import {clearSettings, updateLoggingOut} from "../../store/settingsSlice";
import CAN, {ORG_EXTENSION_MODIFY, ORG_REPORTING} from "../../permissions/ability";
import {useMsal} from "@azure/msal-react";
import {b2cPolicies, msalConfig} from "../../authConfig";
import {AccountInfo} from "@azure/msal-browser";
import {tracker} from "../../store/store";
import {IIconProps} from "@fluentui/react/lib-amd/Icon";
import FeatureRequestPanel from '../FeatureRequestPanel/FeatureRequestPanel';

const UserDropdown: React.FC = () => {
    const personaRef = React.useRef(null);
    const dispatch = useDispatch();
    const [clearWorkspaces] = useClearWorkspaceCacheMutation();
    const [clearSearch] = useClearSearchMutation();
    const [setOrganization] = useSetSelectedOrganizationMutation();
    const [clearExtensions] = useClearExtensionsMutation();
    const [initials, setInitials] = useState('')
    const [open, setOpen] = useState(false);
    const [activeAccount, setActiveAccount] = useState<AccountInfo | undefined | null>();
    const [frOpen, setFrOpen] = useState(false);
    const {instance, accounts, inProgress} = useMsal();

    useEffect(() => {
        const accountClaims = activeAccount?.idTokenClaims
        let firstName = ''
        if((accountClaims) && accountClaims['given_name'] != undefined){
            firstName = accountClaims['given_name'] as string ?? ''
        }
        let lastName = ''
        if((accountClaims) && accountClaims['family_name'] != undefined){
            lastName = accountClaims['family_name'] as string ?? ''
        }
        setInitials(firstName[0] + lastName[0])
        if (activeAccount?.name) {
            tracker.setUserID(activeAccount.name);
        }
    }, [activeAccount] )
    
    useEffect(() => {
        setActiveAccount(instance.getActiveAccount() ?? accounts[0])
    }, [instance]);

    const history = useHistory();

    const logOut = () => {
        dispatch(updateLoggingOut(true));
        clearCache(true);
        instance.logoutRedirect();
    }

    const changePassword = () => {
        const changePasswordRequest = {
            authority: b2cPolicies.authorities.changePassword.authority,
            scopes: ['openid', 'profile', 'email', 'offline_access', msalConfig.auth.clientId],
        };
        instance.loginRedirect(changePasswordRequest);
    }

    const clearCache = (clearSelectedOrg = false) => {
        clearSearch();
        dispatch(clearSettings());
        clearWorkspaces();
        clearExtensions();
        if (clearSelectedOrg)
            setOrganization(undefined);
        dispatch(api.util.resetApiState());
    }

    interface MenuItem {
        key: string,
        itemType?: ContextualMenuItemType,
        text?: string,
        onClick?: any
        iconProps?: IIconProps
    }
    
    const extensionDropDownItem : MenuItem = {
            key: 'Extensions',
            text: 'Extensions',
            onClick: () => {
                history.push('/settings/extensions');
            },
    };
    const catalogDropDownItem : MenuItem= {
            key: 'Catalogs',
            text: 'Catalogs',
            onClick: () => {
                history.push('/settings/catalogs');
            },
    };
    const userDropDownItem : MenuItem = {
        key: 'Users',
        text: 'Users',
        onClick: () => {
            history.push('/settings/users');
        },
    };
    const apiKeyDropdownItem : MenuItem = {
        key: 'API Keys',
        text: 'API Keys',
        onClick: () => {
            history.push('/settings/apikeys');
        },
    };
    const taxCodesDropdownItem : MenuItem = {
        key: 'Tax Codes',
        text: 'Tax Codes',
        onClick: () => {
            history.push('/settings/taxCodes');
        },
    };
    const reportingDropdownItem : MenuItem = {
        key: 'Reporting',
        text: 'Reporting',
        onClick: () => {
            history.push('/reporting/search/usage')
        }
    };
    const logoutDropdownItem : MenuItem= {
        key: 'Logout',
        text: 'Logout',
        onClick: () => {
        logOut();
        },
        iconProps: {iconName: 'SignOut'}
    };
    const clearCacheDropdownItem : MenuItem = {
        key: 'Clear Cache',
        text: 'Clear Cache',
        onClick: () => {
            clearCache();
        },
    };
    const changePasswordDropdownItem : MenuItem= {
        key: 'Change Password',
        text: 'Change Password',
        onClick: () => {
            changePassword();
        },
    };
    const frDropdownItem : MenuItem = {
        key: 'Feature Requests',
        text: 'Feature Requests',
        onClick: () => {
            setFrOpen(true);
        },
    };
    
    //If a display object is passed to another component to render, you need to call any hooks beforehand.
    const canExtensionModify = CAN(ORG_EXTENSION_MODIFY);
    const canOrgReport = CAN(ORG_REPORTING);
    const menuItems: MenuItem[] = [
        {
            key: 'settings',
            text: 'Settings',
            onClick: () => {
                history.push('/settings');
            },
            iconProps: {iconName: 'Settings'}
        },
        frDropdownItem,
        {
            key: 'divider1',
            text: 'Change Password',
            itemType: ContextualMenuItemType.Divider
        },
        clearCacheDropdownItem,
        changePasswordDropdownItem,
        {
            key: 'divider2',
            text: 'Change Password',
            itemType: ContextualMenuItemType.Divider
        },
        logoutDropdownItem,
    ]


    return (
        <>
            <Persona
                className='user-icon'
                imageInitials={initials}
                size={PersonaSize.size32}
                hidePersonaDetails
                onClick={() => setOpen(!open)}
                ref={personaRef}
            />
            <ContextualMenu
                items={menuItems}
                hidden={!open}
                target={personaRef}
                onDismiss={() => setOpen(false)}
            />
            <FeatureRequestPanel isOpen={frOpen} onClose={() => setFrOpen(false)}/>
        </>
    );
}

export default UserDropdown;