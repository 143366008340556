import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { updateSortBestPrice } from '../../store/searchSlice';
import { Icon, TooltipHost } from '@fluentui/react';

interface IBestPriceColumnHeaderProps {
    isDemo?: boolean;
}
const BestPriceColumnHeader: React.FC<IBestPriceColumnHeaderProps> = (props) => {
    const sortDir = useSelector((state: RootState) => state.search.sortBestPrice);
    const dispatch = useDispatch();

    const sort = () => {
        if(props.isDemo) return;
        switch(sortDir) {
            case undefined:
                dispatch(updateSortBestPrice('ASC'));
                break;
            case 'ASC':
                dispatch(updateSortBestPrice('DESC'));
                break;
            case 'DESC':
                dispatch(updateSortBestPrice(undefined));
                break;
        }
    }

    return (
        <div className={"ac-sortable-column-header"} onClick={sort}>
            Best Price
            {sortDir && <>
                <Icon
                    iconName={sortDir === 'ASC' ? 'SortUp' : 'SortDown'}
                    className={"ac-sortable-vendor-header-icon"}
                />
                <TooltipHost content='Best price column only sorts visible results'>
                    <Icon iconName='alertSolid' className={"ac-sortable-vendor-header-icon"} />
                </TooltipHost>
            </>}
        </div>
    );
}

export default BestPriceColumnHeader;