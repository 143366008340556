import React from "react";
import { INormalizedProduct } from "../../models/NormalizedProduct";
import { IVendor } from "../../models/Search";
import { WarehouseTable } from "./WarehouseTable";
import Moment from "moment";
import { Callout, DefaultButton, DirectionalHint } from "@fluentui/react";
import { useId } from '@fluentui/react-hooks';
import AddToWorkspaceButton from "../ProductDetails/AddToWorkspaceButton";

interface IInStockDisplayProps {
    product: INormalizedProduct;
    multiLine?: boolean;
    size?: "big" | "small" | "mini" | "tiny" | "medium" | "large" | "huge" | "massive" | undefined;
    loadingWarehouses: boolean;
    vendorName?: string;
    refreshing?: boolean;
    fluid?: boolean;
    hideWarehouses?: boolean;
    vendor?: IVendor;
    isExample?: boolean;
}

export const InStockDisplay: React.FC<IInStockDisplayProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const id = useId('stock-button');

    let totalStock = 0;
    const stockDisplay = props.loadingWarehouses
        ? <p>Loading</p>
        : <WarehouseTable product={props.product} vendor={props.vendorName} />;
    let inStockDate: Date | undefined = undefined;
    let inStock = false;
    if (props.vendor) {
        if (props.vendor.inStockDate) {
            inStockDate = props.vendor.inStockDate;
        }
        if (props.vendor.onHand != null && props.vendor.onHand > 0) {
            totalStock += props.vendor.onHand;
        }
        inStock = props.vendor.inStock || (props.vendor.onHand != null && props.vendor.onHand > 0);
    } else {
        if (props.product.vendors) {
            props.product.vendors.forEach((vendor: IVendor) => {
                if (!props.vendorName || vendor.vendorName === props.vendorName) {
                    if (vendor.inStockDate &&
                        (inStockDate === undefined || vendor.inStockDate < inStockDate)
                    ) {
                        inStockDate = vendor.inStockDate;
                    }
                    if (vendor.onHand != null && vendor.onHand > 0) {
                        totalStock += vendor.onHand;
                    }
                }
            });
        }
        if (props.vendorName) {
            const vendor = props.product.vendors?.find((v) => v.vendorName === props.vendorName);
            if (vendor) {
                inStock = vendor.inStock || (vendor.onHand != null && vendor.onHand > 0);
            }
        } else if (props.product.stock) {
            inStock = true;
        }
    }
    if (inStock) {
        return (
            <>
                <DefaultButton
                    id={id}
                    primary
                    className="text-center"
                    onClick={() => setOpen(true)}
                    style={{
                        height: props.multiLine ? '3em' : '36px',
                        width: '100%'
                    }}
                    >
                    Stock
                    {props.multiLine ? <br /> : " "}
                    {totalStock > 0 && `(${totalStock})`}
                </DefaultButton>
                {(open && !props.hideWarehouses) && (
                    <Callout
                        style={{
                            padding: '1em',
                        }}
                        directionalHint={DirectionalHint.leftCenter}
                        target={`#${id}`}
                        onDismiss={() => setOpen(false)}
                        role='status'
                        >
                            {stockDisplay}
                            <AddToWorkspaceButton
                                product={props.product}
                                source={props.vendor?.vendorName}
                                onChange={() => setOpen(false)}
                                priceLevel={props.vendor?.priceLevelPartNumber}
                                isExample={props.isExample}
                            />
                        </Callout>
                )}
            </>
        );
    }
    const inStockDateDisplay =
        inStockDate !== undefined
        ? <>Estimated: {props.multiLine ? <br /> : null }{Moment(inStockDate).format("MM-DD-YY")}</>
        : null;
    return <DefaultButton
        color={props.refreshing ? "grey" : "red"}
        className="text-center"
        style={{
            width: '100%',
            height: '36px',
        }}
        // size={props.size}
        // fluid={props.fluid}
        disabled
        >
            {inStockDateDisplay ? inStockDateDisplay : "No Stock" }
        </DefaultButton>;
};
