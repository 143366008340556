import React, {useEffect} from "react";
import {
    setTargetExtension,
    setMappedExtensionFields,
    setRefreshSource,
    clearSettings,
    updateLoggingOut
} from "../store/settingsSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import api, {
    useClearExtensionsMutation,
    useClearSearchMutation,
    useClearWorkspaceCacheMutation,
    useSetSelectedOrganizationMutation,
} from "../store/Api";
import {useMsal} from "@azure/msal-react";
import NotificationModal from "../components/Notification/NotificationModal";
import Footer from "../components/Layout/Footer";
import HomeSearchBar from "../components/HomePage/HomeSearchBar";
import WorkspaceList from "../components/HomePage/WorkspaceList";
import FavoritesList from "../components/HomePage/FavoritesList";
import StorefrontList from "../components/HomePage/StorefrontList";
import ProductIdModal from "../components/DetailsModal/ProductIdModal";
import CAN, {STOREFRONT_READ} from '../permissions/ability';
import { updateSearchCriteria } from "../store/searchSlice";
import { useHistory } from "react-router-dom";
import 'driver.js/dist/driver.css';
import { OPTIONAL_STEP_STOREFRONT, WELCOME_TOUR_KEY, WelcomeTour } from "../tours/WelcomeTour";
import usePageTour from '../hooks/usePageTour';


export const Home: React.FC = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const dismissedNotification = useSelector((state: RootState) => state.settings.dismissedNotification);
    const catalog = useSelector((state: RootState) => state.search.selectedCatalog);
    const [clearWorkspaces] = useClearWorkspaceCacheMutation();
    const [clearSearch] = useClearSearchMutation();
    const [setOrganization] = useSetSelectedOrganizationMutation();
    const [clearExtensions] = useClearExtensionsMutation();
    const {instance} = useMsal(); 
    const dispatch = useDispatch();
    const query = new URLSearchParams(location.search);
    const targetExtension = query.get('source');
    const refreshParentOnExtensionSubmit = query.get('refreshSource')
    const targetExtensionFields = query.get('mappedFields')
    const externalUsername = query.get('username')
    const checkOrg = query.get('acOrg')
    const [qsProduct, setQsProduct] = React.useState<string | undefined>(undefined);
    const canStorefrontRead = CAN(STOREFRONT_READ);
    const history = useHistory();

    const {
        tourOpen,
    } = usePageTour({
        tourKey: WELCOME_TOUR_KEY,
        tourDriver: WelcomeTour,
        enabledOptionalSteps: canStorefrontRead ? [OPTIONAL_STEP_STOREFRONT] : []
    });

    useEffect(() => {
        if (targetExtension && targetExtensionFields) {
            try {
                const mappedFields: { [key: string]: string; } = JSON.parse(targetExtensionFields)
                dispatch(setTargetExtension(targetExtension));
                dispatch(setMappedExtensionFields(mappedFields));
            } catch {
                console.log('Could not map fields.')
            }
        }
    }, [targetExtension, targetExtensionFields]);

    useEffect(() => {
        if (externalUsername) {
            if (auth.user?.email && auth.user.email.toLowerCase() != externalUsername.toLowerCase() ||
                (checkOrg && org?.id && checkOrg != org?.id)) {
                dispatch(updateLoggingOut(true));
                clearSearch();
                dispatch(clearSettings());
                clearWorkspaces();
                clearExtensions();
                setOrganization(undefined);
                dispatch(api.util.resetApiState());
                instance.logoutRedirect()
            }
        }
    }, [instance, externalUsername]);

    useEffect(() => {
        if (refreshParentOnExtensionSubmit) {
            dispatch(setRefreshSource(true))
        }
    }, [refreshParentOnExtensionSubmit]);

    const handleProductSearch = (query: string) => {
        dispatch(updateSearchCriteria({
            keywords: query,
            inStock: false,
            manufacturer: [],
            vendor: [],
            must: [],
            boost: [],
            exclude: [],
            filters: []
        }));
        history.push('/search');
    }
    
    return (<div className="main-content">
        {!dismissedNotification ? <NotificationModal/> : null}
        <div className="home-content">
            <HomeSearchBar
                quicksearchEnabled
                onProductSelected={setQsProduct}
                onProductSearch={handleProductSearch}
                />
            <div className="home-list-group">
                <WorkspaceList />
                <FavoritesList useDemoData={tourOpen} />
                {canStorefrontRead && <StorefrontList useDemoData={tourOpen} />}
            </div>
        </div>
        {qsProduct && <ProductIdModal catalog={catalog?.name ?? ''} productId={qsProduct} onDismiss={() => setQsProduct(undefined)} />}
        <Footer/>
    </div>);
}

export default Home;
