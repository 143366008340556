import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {useGetWorkspaceQuery, usePutWorkspaceMutation} from "../../store/Api";
import {ContextualMenu, Icon, IContextualMenuItem, TextField} from "@fluentui/react";

interface IWorkspaceMarkupHeaderProps {
    isExample?: boolean;
}
const WorkspaceMarkupHeader: React.FC<IWorkspaceMarkupHeaderProps> = (props) => {
    const selectedWorkspace = useSelector((state: RootState) => state.settings.selectedWorkspaceId);
    if (selectedWorkspace == null) return <>Markup</>
    return <WorkspaceMarkupEntry workspace={selectedWorkspace} isExample={props.isExample} />
}

interface IWorkspaceMarkupEntryProps {
    workspace: string;
    isExample?: boolean;
}

const WorkspaceMarkupEntry: React.FC<IWorkspaceMarkupEntryProps> = (props) => {
    const workspace = useGetWorkspaceQuery(props.workspace);
    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const headerRef = React.useRef<HTMLSpanElement>(null);
    const [val, setVal] = React.useState<string | undefined>(undefined);
    const [patchWorkspace] = usePutWorkspaceMutation();

    React.useEffect(() => {
        if (workspace.data) {
            setVal(workspace?.data?.defaultMarkup?.toString());
        }
    }, [workspace.data?.defaultMarkup]);

    const update = (v: string | undefined) => {
        if(props.isExample) return;
        if (workspace.data) {
            patchWorkspace({
                ...workspace.data,
                id: props.workspace,
                defaultMarkup: v ? parseFloat(v) : undefined,
            });
            setShowMenu(false);
        }
    }

    const menuItems: IContextualMenuItem[] = [
        {
            onRender: () =>
                <div style={{margin: '1em'}}>
                    <TextField
                        placeholder={'Not Set'}
                        style={{padding: '1em'}}
                        value={val}
                        label={'Default Markup'}
                        type={'number'}
                        suffix={'%'}
                        onChange={(e,v) => {
                            setVal(v);
                        }}
                        onBlur={(e) => update(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                update(e.currentTarget.value);
                            }
                        }}
                    />
                </div>,
            key: 'markup',
        }
    ]
    return (<>
        <div
            onClick={() => {
                setShowMenu(!showMenu);
            }}
            style={{
                cursor: 'pointer',
                width: '400px',
            }}
        >
            <span ref={headerRef}>
                Markup {workspace.data?.defaultMarkup && `(${workspace.data.defaultMarkup}%)`}
                <ContextualMenu items={menuItems} hidden={!showMenu} target={headerRef}/>
                <Icon iconName={'Edit'} style={{marginLeft: '0.5em'}}/>
            </span>
        </div>
    </>);
}

export default WorkspaceMarkupHeader;