import {
    DefaultButton, 
    IContextualMenuProps,
    Label,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PanelType,
    PrimaryButton,
} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import {
    useGetStorefrontsQuery,
    useSaveSearchMutation,
} from "../../store/Api";

import {
    ISavedSearch, ISavedSearchRequest,
    ISavedSearchStorefrontDetails,
    ISearchRequest
} from "../../models/Search";
import EditableImage from "../DetailsModal/EditableImage";
import SavedSearchStorefrontSettings from "./SavedSearchStorefrontSettings";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {cloneDeep} from "lodash";
import DataField from "../Common/DataField";
import ToggleField from "../Common/ToggleField";
import PanelWrapper from "../Common/PanelWrapper";

interface ISaveSearchPanel {
    criteria?: ISearchRequest
    savedSearch?: ISavedSearch;
    closer: () => void;
    onUpdate: (newId?: string) => void;
}
const SaveSearchPanel: React.FC<ISaveSearchPanel> = props => {
    const storefronts = useGetStorefrontsQuery();
    const [saveSearch] = useSaveSearchMutation()
    const [saving, setSaving] = useState(false);
    const [savedSearch, setSavedSearch] = useState<ISavedSearch>({});
    const [saveError, setSaveError] = useState<string>();
    const selectedCatalog = useSelector((state: RootState) => state.search.selectedCatalog)
    const [storefrontMap, setStorefrontMap] = useState<{[p: string]: ISavedSearchStorefrontDetails}>();
    const [storefrontIds, setStorefrontIds] = useState<string[]>([]);

    useEffect(() => {
        if(storefronts.data?.items){
            const ids = storefronts.data.items.map(storefront => storefront.id ?? '')
            if(ids) {
                setStorefrontIds(ids)
            }
        }
    }, [storefronts.data]);
    useEffect(() =>{
        if(props.criteria) {
            const criteria: ISavedSearchRequest = cloneDeep(props.criteria)
            criteria.catalog = selectedCatalog?.name
            setSavedSearch({
                id: props.savedSearch?.id,
                criteria: criteria,
                name: props.savedSearch?.name,
                storefronts: props.savedSearch?.storefronts,
                shared: props.savedSearch?.shared,
                image: props.savedSearch?.image,
                imageId: props.savedSearch?.imageId,
                markup: props.savedSearch?.markup,
                markupType: props.savedSearch?.markupType,
                minimumCost: props.savedSearch?.minimumCost,
                minimumPrice: props.savedSearch?.minimumPrice,
                storefrontDetails: props.savedSearch?.storefrontDetails,
                overrideDescription: props.savedSearch?.overrideDescription,
            })
        }
        

    }, [props.criteria, props.savedSearch])

    useEffect(() => {
        if(storefrontIds && savedSearch) {
            const detailsMap: { [p: string]: ISavedSearchStorefrontDetails } = {};
            storefrontIds.forEach(id => {
                detailsMap[id] = {storefrontId: id};
            });

            savedSearch?.storefrontDetails?.forEach(storefrontDetail => {
                if (storefrontDetail.storefrontId && storefrontDetail.storefrontId) {
                    detailsMap[storefrontDetail.storefrontId] = storefrontDetail
                }
            })
            setStorefrontMap(detailsMap)
        }
    }, [savedSearch, storefrontIds]);

    const contentStyles = mergeStyleSets({
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        main: {
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        navigation: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        footerInner: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        content: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        commands: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
    });

    const buttonMenuProps: IContextualMenuProps = {
        items: [
            {
                key: 'saveCopy',
                text: 'Save as Copy',
                iconProps: { iconName: 'SaveAs' },
                onClick: () => {
                    save(true)
                },
            }
        ],
    };
    
    const save = (forceNew?: boolean) => {
        setSaveError('')
        if(!savedSearch) return;
        
        setSaving(true);
        if(forceNew){
            saveSearch({savedSearch: {...savedSearch, id: undefined}}).unwrap().then(result => {
                setSaving(false);
                if(!result.success) {
                    setSaveError(result.message ?? "Error saving search")
                    return;
                }
                props.onUpdate(result.id)
                props.closer()
            });
        }
        else {
            saveSearch({savedSearch: savedSearch}).unwrap().then(result => {
                setSaving(false);
                if (!result.success) {
                    setSaveError(result.message ?? "Error saving search")
                    return;
                }
                props.onUpdate(result.id)
                props.closer()
            });
        }
    }
    
    const footerButtons = () => [
        <PrimaryButton text='Save' iconProps={ {iconName: 'Save'}} split menuProps={savedSearch.id ? buttonMenuProps : undefined} onClick={() => save()} disabled={saving} />,
        <DefaultButton text='Cancel' onClick={() => {props.closer(); setSaveError('')}}/>
    ]
    
    const updateStorefrontDetails = (details: {[p: string]: ISavedSearchStorefrontDetails}) => {
        const keys = Object.keys(details)
        const detailsArray = keys.map(key => details[key])
        setSavedSearch({...savedSearch, storefrontDetails: detailsArray})
    }


    return (
        <div>
            <PanelWrapper
                isOpen={true}
                onDismiss={() => {
                    props.closer()
                    setSaveError('')
                }}
                
                className={"saved-search-panel"}
                type={PanelType.custom}
                customWidthPx={850}
                isFooterAtBottom={true}
                footerButtons={footerButtons()}
                title={"Save Search"}
                styles={{
                    root: {
                        zIndex: 10,
                        marginTop: '48px',
                    },
                    content: contentStyles.content,
                    commands: contentStyles.commands,
                    scrollableContent: contentStyles.scrollableContent,
                    navigation: contentStyles.navigation,
                    footerInner: contentStyles.footerInner,
                }}
                headerBottom={<div>{saveError && <MessageBar messageBarType={MessageBarType.error}>{saveError}</MessageBar>}</div>}
            >
                
                <DataField
                    label={'Saved Search Name'}
                    value={savedSearch.name}
                    onChange={(e, v) => setSavedSearch({...savedSearch, name: v})}
                    toolTip={"This will also be the product name for any storefronts"}
                />
                
                <div style={{paddingTop: '1em'}}>
                    <ToggleField
                        checked={savedSearch.shared}
                        onChange={(e, v) => setSavedSearch({...savedSearch, shared: v})}
                        label={'Shared'}
                        toolTip={"If checked, the saved search result will be available to other users, and can be used as a storefront item."}
                    />
                    
                </div>
                {savedSearch.shared &&
                    <div style={{paddingTop: '1em'}}>
                        <DataField
                            multiline={true}
                            label={'Override Item Description'}
                            value={savedSearch.overrideDescription}
                            onChange={(e, v) => setSavedSearch({...savedSearch, overrideDescription: v})}
                            toolTip={"Override the item description. If blank, the saved search result item's description will be used."}
                        />
                        <SavedSearchStorefrontSettings
                            minimumCost={savedSearch.minimumCost}
                            minimumPrice={savedSearch.minimumPrice}
                            onStorefrontMinimumCostChange={(minimumCost) => {
                                setSavedSearch({...savedSearch, minimumCost: minimumCost})
                            }}
                            onStorefrontMinimumPriceChange={(minimumPrice) => {
                                setSavedSearch({...savedSearch, minimumPrice: minimumPrice})
                            }}
                            storefrontOverrideMarkup={savedSearch.markup}
                            storefrontOverrideMarkupType={savedSearch.markupType}
                            includedStorefronts={savedSearch.storefronts}
                            storefronts={storefrontMap}
                            onIncludedStorefrontsChange={(includedStorefronts) => {
                                setSavedSearch({...savedSearch, storefronts: includedStorefronts})
                            }}
                            onStorefrontsChange={(storefronts) => updateStorefrontDetails(storefronts)}
                            onStorefrontOverrideMarkupChange={(markup) => {
                                setSavedSearch({...savedSearch, markup: markup})
                            }}
                            onStorefrontOverrideMarkupTypeChange={(markupType) => {
                                setSavedSearch({...savedSearch, markupType: markupType})
                            }}
                        />

                        <Label style={{paddingTop: '2em'}}>Storefront Feature Thumbnail</Label>
                        <EditableImage
                            image={{
                                url: savedSearch.image ?? '',
                                type: 'Custom',
                                name: 'Storefront Feature Thumbnail',
                                id: savedSearch.imageId ?? crypto.randomUUID(),
                                isCustom: true,
                            }}
                            editMode={true}
                            onChange={(id, url) => setSavedSearch({...savedSearch, image: url, imageId: id})}
                            onClear={() => {setSavedSearch({...savedSearch, image: '', imageId: undefined})}}
                        />
                    </div>
                }
            </PanelWrapper>
        </div>

    );
}
export default SaveSearchPanel;