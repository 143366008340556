import { ICatalog } from "./Catalog";

export interface IGetOrganizationsResponse {
    organizations: IOrganization[];
}

export interface IOrganization {
    id: string;
    name: string;
    logo?: string;
    defaultCatalog?: string;
    country?: string;
    catalogs: ICatalog[];
    configurations?: IConfiguration[];
    users?: IUserRoleRecord[];
    userLimit: number;
    catalogLimit: number;
    licensedUserCount?: number;
    searchCredits?: number;
    subOrganizationsEnabled: boolean;
    productLevel: string;
    apiUsageBillingEnabled: boolean;
    llmEnabled: boolean;
    customerRequestAnalyzerEnabled?: boolean;
    documentAnalyzerEnabled?: boolean;
    procurementEnabled: boolean;
    contact1?: string;
    contact2?: string;
    contactEmail?: string;
    contactPhone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    stateProvince?: string;
    postalCode?: string;
    punchoutCurrency?: string;
    excludeFromBestPrice?: string[];
    storefrontTier: string;
    storefrontLimit: number;
    storefrontProductLimit: number;
    storefrontSavedSearchLimit: number;
    storefrontTransactionLimit: number;
    storefrontDomain: string;
    storefrontDomains: string[];
}

export const STOREFRONT_TIER_STARTER = 'Starter';
export const STOREFRONT_TIER_PROFESSIONAL = 'Professional';
export const STOREFRONT_TIER_ENTERPRISE = 'Enterprise';

export interface IUserRoleRecord {
    username: string;
    role: string;
    mfaMode?: string;
}

export interface IUserCreateRequest extends IUserRoleRecord {
    firstName?: string;
    lastName?: string;
}

export interface IDisplayedField {
    id: number;
    field: string;
    size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16
}

export interface IConfiguration {
    name: string;
    description: string;
    protected: boolean;
    dataType: string;
    defaultValue: string;
}

export interface IGetPermissionsResponse {
    permissions: string[];
    allPermissions: {[key: string]: string}
}

export interface IColumns {
    searchColumns: string[];
    workspaceColumns: string[];
}

export interface ICustomColumnSet {
    columnType: string;
    id?: string;
    columns?: string[]
}

export interface ISubOrganization {
    id: string;
    parentOrganization: string;
    name: string;
    logo?: string;
    country?: string;
    procurementEnabled?: boolean;
    contact1?: string;
    contact2?: string;
    contactEmail?: string;
    contactPhone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    stateProvince?: string;
    postalCode?: string;
}

export interface ICreateSubOrganizationRequest {
    name: string;
    logo?: string;
    country?: string;
    contact1?: string;
    contact2?: string;
    contactEmail?: string;
    contactPhone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    stateProvince?: string;
    postalCode?: string;
}

export interface IUpdateOrganizationRequest {
    name?: string;
    logo?: string;
    country?: string;
    defaultCatalog?: string;
    contact1?: string;
    contact2?: string;
    contactEmail?: string;
    contactPhone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    stateProvince?: string;
    postalCode?: string;
    punchoutCurrency?: string;
    excludeFromBestPrice?: string[];
}

export interface IUserSettings {
    keywordToTags?: boolean;
}

export interface IUserSettingsUpdateRequest {
    keywordToTags?: boolean;
}

export const CURRENCIES = [
    'USD',
    'CAD',
    'EUR',
    'GBP',
    'AUD',
    'NZD',
]

export interface IVendorSettings {
    sourceVendor: string;
    vendorDisplayName?: string;
    enabled?: boolean;
    punchoutVendorName?: string;
}