import React from 'react';
import {ContextualMenu, Icon, IconButton, IContextualMenuItem, TextField} from "@fluentui/react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {updateSearchCriteria} from "../../store/searchSlice";
import { useGetVendorSettingsQuery } from '../../store/Api';

interface ISortableColumnHeaderProps {
    label: string;
    field: string;
    vendor?: string;
    menu?: IContextualMenuItem[];
    criteriaFilter?: string;
    isDemo?: boolean;
}

const SortableColumnHeader: React.FC<ISortableColumnHeaderProps> = (props) => {
    const vendorSettings = useGetVendorSettingsQuery(props.vendor ?? '', {skip: (!props.vendor || props.isDemo)});
    const criteria = useSelector((state: RootState) => state.search.criteria);
    const divRef = React.useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const [filterValue, setFilterValue] = React.useState<string | undefined>();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (props.criteriaFilter) {
            const i = Object.keys(criteria).indexOf(props.criteriaFilter);
            if (i > -1) {
                setFilterValue(Object.values(criteria)[i]);
            }
        }
    }, [criteria, props.criteriaFilter]);

    const updateFilter = (v: string | undefined) => {
        if (props.criteriaFilter && !props.isDemo)
            dispatch(updateSearchCriteria({...criteria, [props.criteriaFilter]: v}));
    }

    const sort = () => {
        const newCriteria = {...criteria};
        if (newCriteria.sortField === props.field && newCriteria.sortVendor === props.vendor) {
            if (newCriteria.sortDirection === 'DESC') {
                newCriteria.sortDirection = 'ASC';
            } else {
                newCriteria.sortDirection = undefined;
                newCriteria.sortField = undefined;
                newCriteria.sortVendor = undefined;
            }
        } else {
            newCriteria.sortDirection = 'DESC';
            newCriteria.sortField = props.field;
            newCriteria.sortVendor = props.vendor;
        }
        if(!props.isDemo) {
            dispatch(updateSearchCriteria(newCriteria));
        }
    }

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'filter',
            onRender: () => (
                <div style={{padding: '1em'}}>
                    <TextField
                        placeholder='Not Set'
                        label={`${props.label} Filter`}
                        value={filterValue}
                        onChange={(e,v) => {
                            if (!props.isDemo) {
                                setFilterValue(v)
                            }
                        }}
                        onBlur={(e) => {
                            if(!props.isDemo){
                                updateFilter(e.target.value)
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !props.isDemo) {
                                updateFilter(e.currentTarget.value);
                            }
                        }}
                        />
                </div>
            )
        }
    ];

    return (<>
        <div className={"ac-sortable-column-header"} onClick={sort} ref={divRef}>
            {vendorSettings.data?.vendorDisplayName ?? props.label}
            {criteria.sortField === props.field && criteria.sortVendor === props.vendor &&
                <Icon
                    iconName={criteria.sortDirection === 'ASC' ? 'SortUp' : 'SortDown'}
                    className={"ac-sortable-vendor-header-icon"}
                />
            }
        </div>
        {props.criteriaFilter && <div style={{right: '3px', position: 'absolute'}}>
            <IconButton
                iconProps={{ iconName: 'Filter' }}
                onClick={() => setShowMenu(true)}
            />
        </div>}
        <ContextualMenu items={menuItems} hidden={!showMenu} target={divRef} onDismiss={() => setShowMenu(false)}/>
    </>);
}

export default SortableColumnHeader;