import {
    CommandBar,
    DefaultButton,
    DetailsList,
    Dialog,
    DialogFooter,
    DialogType, 
    IColumn, 
    ICommandBarItemProps,
    mergeStyleSets,
    MessageBar, 
    MessageBarType,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    SelectionMode,
    TextField,
} from "@fluentui/react";
import React, {useEffect, useRef, useState} from "react";
import {
    useGetOrderEventsQuery,
    useGetOrderFulfillmentsQuery,
    useGetOrderItemsQuery,
    useUpdateOrderStatusMutation,
    useLazyGetOrderVendorUpdateQuery, 
    useGetExtensionIdQuery, 
    useCreateCsvMutation
} from "../../store/Api";
import {ICompany} from "../../models/Company";
import {IOrder, IProcurementEvent, IProcurementItem} from "../../models/Order";
import {renderOrderItemColumns} from "../../logic/Columns/ProcurementItemColumns";
import WorkspaceCompanyDetails from "../Workspace/WorkspaceCompanyDetails";
import Accordion from "../Common/Accordion";
import ProcurementRequirements from "./ProcurementRequirements";
import {Dropdown, IDropdownOption} from "@fluentui/react/lib/Dropdown";
import DataField from "../Common/DataField";
import ToggleField from "../Common/ToggleField";
import {renderFulfillmentColumns, renderProcurementEventColumns} from "../../logic/Columns/OrderColumns";
import {copyAndSort} from "../../logic/Columns/ColumnUtil";

interface IOrderModalProps {
    order: IOrder;
    closer: () => void;
}
const OrderModal: React.FC<IOrderModalProps> = props => {
    const [orderCompany, setOrderCompany] = useState<ICompany>();
    const [endUserCompany, setEndUserCompany] = useState<ICompany>();
    const [orderUpdater] = useLazyGetOrderVendorUpdateQuery();
    const [updateOrderStatus] = useUpdateOrderStatusMutation();
    const getFulfillments = useGetOrderFulfillmentsQuery({orderId: props.order.id ?? ''}, {skip: !props.order.id || props.order.id == ''})
    const getExtensionId = useGetExtensionIdQuery({source: props.order.source ?? ''}, {skip: !props.order.source || props.order.source == ''})
    const getEvents = useGetOrderEventsQuery({orderId: props.order.id ?? ''}, {skip: !props.order.id || props.order.id == ''})
    const [currentItems, setCurrentItems] = useState<IProcurementItem[]>()
    const [displayConfirmModal, setDisplayConfirmModal] = useState<boolean>();
    const orderItems = useGetOrderItemsQuery({orderId: props.order.id ?? ""},
        {skip: props.order.id == undefined || props.order.id == ''})
    const [itemRequirements, setItemRequirements] = useState<boolean>()
    const [columns, setColumns] = useState<IColumn[]>([])
    const [orderStatus, setOrderStatus] = useState<string>()
    const [pendingOrderStatus, setPendingOrderStatus] = useState<string>()
    const [extensionId, setExtensionId] = useState<string | undefined>();
    const [csvLink, setCsvLink] = useState<string>();
    const [displayCsvModal, setDisplayCsvModal] = useState<boolean>();
    const [generatingCsv, setGeneratingCsv] = useState<boolean>()
    const [createCSV] = useCreateCsvMutation();
    const [warningMessage, setWarningMessage] = useState<string>();
    const [events, setEvents] = useState<IProcurementEvent[]>([])
    const [orderRefreshing, setOrderRefreshing] = useState<boolean>()
    const eventsRef = useRef(events)
    const eventColumns = useRef<IColumn[]>([])
    useEffect(() => {
        if(orderItems.data) {
            setCurrentItems(orderItems.data)
        }
    }, [orderItems]);
    useEffect(() => {
        if(getExtensionId.data) {
            setExtensionId(getExtensionId.data)
        }
    }, [getExtensionId]);
    useEffect(() => {
        if(getEvents.data){
            changeEvents(getEvents.data)
            eventColumns.current = renderProcurementEventColumns(sortEventColumns);
        }
    }, [getEvents]);
    useEffect(() =>{
        if(currentItems){
            const itemsWithRequirements = currentItems.filter(a => 
                a.procurementRequirements && a.procurementRequirements.length > 0 && a.procurementRequirements
                    .filter(a => a.selectedValues?.[0] && a.selectedValues[0] != '' && a.selectedValues[0] != 'N/A')?.length > 0)
            if(itemsWithRequirements && itemsWithRequirements.length > 0){
                setItemRequirements(true)
            }
        }
    }, [currentItems])

    useEffect(() => {
        setOrderCompany(props.order.orderCompany)
        setEndUserCompany(props.order.endUserDetails)
        setOrderStatus(props.order.status)
    }, [props.order]);

    useEffect(() => {
        const tempColumns = renderOrderItemColumns(undefined, true)
        setColumns(tempColumns)
    }, [currentItems]);
    
    const changeEvents = (events: IProcurementEvent[]) => {
        setEvents(events)
        eventsRef.current = events
    }
    const sortEventColumns = (column: IColumn): void => {
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const newSortedItems = copyAndSort(eventsRef.current, column.fieldName!, isSortedDescending);

        eventColumns.current = eventColumns.current.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });

        changeEvents(newSortedItems);
    };
    const commands: ICommandBarItemProps[] = [
        {
            disabled: (!extensionId || extensionId == '' || !props.order.active || orderRefreshing),
            key: 'Refresh',
            text: (orderRefreshing ? 'Refreshing Order' : 'Refresh Order'),
            iconProps: {iconName: 'Refresh'},
            onClick: () => {
                if(props.order.id && props.order.active) {
                    setOrderRefreshing(true)
                    orderUpdater({orderId: props.order.id}).then(() => {
                        getFulfillments.refetch();
                        getEvents.refetch();
                        setOrderRefreshing(false)
                    })
                }
            }
        },
        {
            disabled: generatingCsv,
            key: 'GenerateCsv',
            text: (generatingCsv ? 'Generating' : 'Generate CSV'),
            iconProps: {iconName: 'Export'},
            onClick: () => {
                if(props?.order?.itemIds && props.order.itemIds.length > 0) {
                    setGeneratingCsv(true)
                    createCSV({request: {procurementItemIds: props.order.itemIds}}).unwrap().then(a => {
                        if(!a.success){
                            setGeneratingCsv(false)
                            setWarningMessage(`CSV Generation Error: ${a.error ?? 'Unable to generate CSV'}`)
                        }
                        else if(a.url && a.url != ''){
                            setDisplayCsvModal(true)
                            setCsvLink(a.url)
                            setGeneratingCsv(false)
                        }
                    })
                }
            }
        }
    ]
    const contentStyles = mergeStyleSets({
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        main: {
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        navigation: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        footerInner: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        content: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        commands: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
    });

    const close = () => {
        props.closer()
        setWarningMessage('')
    }
    
    const getProcurementItemQuestions = (item: IProcurementItem, index: number) => {
        if(!item.procurementRequirements || item.procurementRequirements.length === 0) return <></>
        const itemHeader = (<span style={{display: 'inline-flex'}}>
            <h3>{item.name} - {item.manufacturerPartNumber}</h3>
        </span>)
        return(<div key={`orderstatusitemreq-${item.id}`}><Accordion titleComponent={itemHeader} beginExpanded={index === 0} useChevron={true}>
            <ProcurementRequirements 
                requirements={item.procurementRequirements?.filter(a => a.selectedValues?.[0] 
                    && a.selectedValues?.[0] != '' && a.selectedValues?.[0] != 'N/A') ?? []}
                source={item.source}
                showCategoryToggles={false}
                readonly={true}
            />
            
        </Accordion></div>)
    }
    
    const getProcurementItemElements = () => {
        return(currentItems?.map((si, index) => getProcurementItemQuestions(si, index)))
    }

    const orderStatusOptions: IDropdownOption[] = [
        {key: '', text: '[No Status]'},
        {key: 'Submitted', text: 'Submitted'},
        {key: 'Confirmed', text: 'Confirmed'},
        {key: 'Pending', text: 'Pending'},
        {key: 'In Progress', text: 'In Progress'},
        {key: 'Backordered', text: 'Backordered'},
        {key: 'Partial', text: 'Partially Complete'},
        {key: 'Complete', text: 'Complete'},
        {key: 'Cancelled', text: 'Cancelled'},
        {key: 'CREDIT HOLD', text: 'Credit Hold'}
    ];
    
    return (
        <div key={`orderstatusmodal-${props.order.id}`}>
            <Panel
                onOuterClick={() => {console.log("swallowing modal close event")}}
                isOpen={true}
                onDismiss={close}
                className={"order-panel"}
                isFooterAtBottom={true}
                type={PanelType.large}
                title={"Order"}
                styles={{
                    root: {
                        zIndex: 10,
                        marginTop: '48px'
                    },
                    content:contentStyles.content,
                    commands:contentStyles.commands,
                    scrollableContent: contentStyles.scrollableContent,
                    navigation: contentStyles.navigation,
                    footerInner: contentStyles.footerInner,
                }}
                onRenderFooter={() => (
                    <div className={'fixed-footer'}>
                        <PrimaryButton
                            style={{width: '100%', height: '40px'}}
                            onClick={() => close()}
                            text={"Close"}
                        />
                        </div>)}
            >
                <h1>Order {props.order.name ? props.order.name : props.order.source}</h1>
                {warningMessage &&
                    <MessageBar messageBarType={MessageBarType.blocked}>{warningMessage}</MessageBar>}
                <CommandBar items={[]} farItems={commands}/>
                    <div>
                        <div style={{paddingBottom: '10px', width: '200px'}}>
                            <Dropdown
                                disabled={orderStatus == 'Cancelled' || orderStatus == "Complete"}
                                dropdownWidth={150}
                                options={orderStatusOptions}
                                label={'Order Status'}
                                selectedKey={orderStatus}
                                onChange={(e, status) => {
                                    if(status?.key) {
                                        setPendingOrderStatus(status.key.toString())
                                        setDisplayConfirmModal(true)
                                    }
                                }}
                            />
                        </div>
                        <div style={{display: 'inline-flex', backgroundColor: "rgba(0, 0, 0, .1)", width: '100%'}}>

                            <DataField
                                disabled={true}
                                label={"Order Name"}
                                value={props.order.name ?? ""}
                            />
                            <DataField
                                disabled={true}
                                label={"Vendor"}
                                value={props.order.source ?? ""}
                            />
                            <DataField
                                disabled={true}
                                label={"Vendor Order Number"}
                                value={props.order.externalOrderId ?? ""}
                            />
                            <DataField
                                disabled={true}
                                label={"PO Number"}
                                value={props.order.purchaseOrderNumber ?? ""}
                            />
                            <DataField
                                disabled={true}
                                label={"Customer Order Number"}
                                value={props.order.endUserOrderNumber ?? ""}
                            />
                            <ToggleField
                                disabled={true}
                                label={"Dropship"}
                                checked={props.order.dropship}

                            />
                            <ToggleField
                                disabled={true}
                                label={"Allow Backorder"}
                                checked={props.order.allowBackorder}
                            />
                        </div>
                        <div>

                        </div>
                    </div>
                <Pivot overflowBehavior='menu' styles={{itemContainer: {marginTop: '1em'}}}>
                    <PivotItem headerText='Order Company'>
                        <WorkspaceCompanyDetails
                            showParsedAddress={true}
                            currentCompany={orderCompany}
                        />
                    </PivotItem>
                    <PivotItem headerText='Customer Company'>
                        <WorkspaceCompanyDetails
                            showParsedAddress={true}
                            currentCompany={endUserCompany}
                        />
                    </PivotItem>
                    <PivotItem headerText='Fulfillments'>
                        <DetailsList
                            items={getFulfillments.data ?? []} 
                            columns={renderFulfillmentColumns()}
                            selectionMode={SelectionMode.none}/>
                    </PivotItem>
                    <PivotItem headerText='Events'>
                        <DetailsList
                            items={eventsRef.current ?? []}
                            columns={eventColumns.current}
                            selectionMode={SelectionMode.none}/>
                    </PivotItem>
                    {props.order.procurementRequirements && props.order.procurementRequirements.length > 0 && 
                    <PivotItem headerText={'Vendor Specific Order Options'}>
                        <ProcurementRequirements
                            requirements={props.order.procurementRequirements ?? []}
                            source={props.order.source}
                            showCategoryToggles={false}
                        />
                    </PivotItem>}
                    {props.order.orderNotes && <PivotItem headerText='Order Notes'>
                        <div>
                            <TextField
                                disabled={true}
                                multiline={true}
                                rows={5}
                                value={props.order.orderNotes}
                            />
                        </div>
                    </PivotItem>}
                    <PivotItem headerText={'Items'}>
                        <DetailsList
                            items={currentItems ?? []}
                            columns={columns}
                            selectionMode={SelectionMode.none}/>
                    </PivotItem>

                    {itemRequirements &&
                        <PivotItem headerText={`${props.order.source} Item Options`}>
                            {getProcurementItemElements()}
                        </PivotItem>
                    }
                </Pivot>
            </Panel>

            <Dialog
                hidden={!displayConfirmModal}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Order Status Change Confirmation',
                    subText: (`Changing the Order Status will not make any updates to the order in the vendor's system. Are you sure you want to continue?`),
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <DialogFooter>
                    <DefaultButton
                        primary
                        text='Confirm'
                        onClick={() => {
                            if(props.order.id && pendingOrderStatus) {
                                updateOrderStatus({orderId: props.order.id, status: pendingOrderStatus}).unwrap().then(a => {
                                    if(a){
                                        setOrderStatus(pendingOrderStatus)
                                        getEvents.refetch();
                                    }
                                })
                            }
                            setDisplayConfirmModal(false)
                        }}
                    />
                    <DefaultButton
                        primary
                        text='Cancel'
                        onClick={() => {
                            setDisplayConfirmModal(false)
                        }}
                    />
                </DialogFooter>
                
            </Dialog>

            <Dialog
                hidden={!displayCsvModal}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'CSV Generated',
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <a href={csvLink}  target={"new"} title={'Open CSV'}>Open CSV</a>
                <DialogFooter>

                    <DefaultButton
                        primary
                        text='OK'
                        onClick={() => {
                            setDisplayCsvModal(false)
                        }}
                    />
                </DialogFooter>

            </Dialog>
            
        </div>

    );
}
export default OrderModal;