import {Icon, Shimmer} from "@fluentui/react";
import React, {useEffect, useRef, useState} from "react";
import {useGetGridConfigurationQuery} from "../../store/Api";
import {GridSelectionMode, IColumnDetails} from "../../models/GridConfiguration";
import CurrencyDisplay from "../Currency/CurrencyDisplay";
import FilterableSortableColumnHeader from "../ExtensionModal/SortableDirectImportPreviewHeader";
import ToggleField from "../Common/ToggleField";

interface IDataGridProps {
    enableShimmer?: boolean;
    items: any[],
    selectionMode: GridSelectionMode,
    gridSource?: string,
    gridId?: string,
    selections?: number[],
    pageSize?: number,
    loading: boolean,
    onFilterChange: (filter?: string, filterField?: string) => void,
    onSortChange: (sortBy?: string, sortDirection?: string) => void,
    onColumnChange?: (column: IColumnDetails[]) => void,
    overrideColumns?: IColumnDetails[],
    canDrag?: boolean,
    canResize?: boolean
    isCustomizeModal?: boolean,
    sortBy?: string,
    sortDirection?: string,
    filter?: string,
    filterField?: string
}

const DataGrid: React.FC<IDataGridProps> = (props) => {
    const [filter, setFilter] = React.useState('');
    const [filterField, setFilterField] = React.useState('');
    const [sortField, setSortField] = React.useState('');
    const [sortDirection, setSortDirection] = React.useState('');
    const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
    const [multiSelectKeys, setMultiSelectKeys] = React.useState<boolean>(false);
    const [columnDetails, setColumnDetails] = React.useState<IColumnDetails[]>([])
    const [columnKeys, setColumnKeys] = React.useState<string[]>([])
    const [tableHeight, setTableHeight] = useState('auto');
    const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

    useEffect(() => {
        if(props.filterField){
            setFilterField(props.filterField)
        }
        if(props.filter){
            setFilter(props.filter)
        }
        if(props.sortBy){
            setSortField(props.sortBy)
        }
        if(props.sortDirection){
            setSortDirection(props.sortDirection)
        }
    }, [props.filter, props.filterField, props.sortBy, props.sortDirection]);
    
    
    const getGrid = useGetGridConfigurationQuery({gridIdentifier: props.gridSource ?? "", gridIdHint: props.gridId ?? ''}, {skip: !props.gridSource || props.gridSource == '' || !props.gridId || props.gridId == ''});
    
    
    const tableElement = useRef(null);

    const [dragOver, setDragOver] = React.useState(false);
    const handleDragOverStart = () => setDragOver(true);
    const handleDragOverEnd = () => setDragOver(false);
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('text', event.currentTarget.id);
    }
    const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, currentColumn: IColumnDetails) => {
        const id = event.dataTransfer.getData('text');
        const sourceIndex = columnDetails.findIndex((item) => item.identifier === id);
        const destinationIndex = columnDetails.findIndex((item) => item.identifier === currentColumn.identifier);
        if (sourceIndex !== -1 && destinationIndex !== -1) {
            const initialColumns = [...columnDetails];
            const [removed] = initialColumns.splice(sourceIndex, 1); // Remove the dragged column
            initialColumns.splice(destinationIndex, 0, removed); // Insert it at the current column's index
            setColumnDetails(() => [...initialColumns]); // Update the state with the new order
            props?.onColumnChange?.([...initialColumns]);
        }
        setDragOver(false);
    }
    useEffect(() => {
        setColumnKeys(getGrid.data?.visibleColumns ?? [])
    }, [getGrid.data]);

    useEffect(() => {
        const initialSet: IColumnDetails[] = []
        
        columnKeys.forEach((key) => {
            const found = getGrid.data?.columnDetails?.find(a => a.identifier === key)
            if(found){
                initialSet.push(found)
            }
        })
        //If override columns are present and they are not marked as required, we check if there's an existing column with the same identifier, and replace it if so.
        //Otherwise, if it's not found we ignore it. If it is marked as required, it will always be added 
        if (props.overrideColumns) {
            props.overrideColumns.forEach((column, ) => {
                const foundIndex = initialSet.findIndex(a => a.identifier == column.identifier)
                if(foundIndex != -1){
                    initialSet[foundIndex] = column;
                }
                else if(column.index != undefined && (column.required || props.isCustomizeModal)) {
                    initialSet.splice(column.index, 0, column)
                }
                else if(column.required || props.isCustomizeModal){
                    initialSet.push(column)
                }
            })
        }
        if (props.selectionMode === GridSelectionMode.multiple || props.selectionMode === GridSelectionMode.single) {
            initialSet.splice(0, 0, {identifier: 'selector', label: '', type: 'Selector', minimumWidth: 20})
        }

        setColumnDetails(() => [...initialSet])

    }, [columnKeys, props.overrideColumns]);

    const getShimmer = () => {
        const shimmerRows = [];
        for (let i = 0; i < (props?.pageSize ?? 25); i++) {
            shimmerRows.push(<tr key={`shimmer-${i}${(props.isCustomizeModal? '-customize' : '')}`} className={'composer-row'}>
                {columnDetails?.map((column) => {
                    return <td key={`shimmer-${i}-${column.identifier}${(props.isCustomizeModal? '-customize' : '')}`} style={{minWidth: column.minimumWidth}}
                               className={'composer-cell'}><Shimmer/></td>
                })}
            </tr>)
        }
        return shimmerRows
    }
    
    //Add any new column types here
    const getTypedValue = (column: IColumnDetails, index: number, value?: string) => {
        if (column.component) {
            return <div onClick={() => column.onClick?.(props.items[index])}
                        onBlur={() => column.onBlur?.(props.items[index], value ?? '')}>{column.component(props.items[index])}</div>
        }
        if (column.type === 'Number') {
            return <div>{value ? value.toLocaleString() : '-'}</div>
        }
        if (column.type === 'DateTime') {
            return <div>{value ? new Date(value).toLocaleDateString() : '-'}</div>
        }
        if (column.type === 'Boolean') {
            return <ToggleField disabled={true} checked={value?.toLowerCase() == 'true'} width={column.effectiveWidth}/>
        }
        if (column.type === 'Currency') {
            return <CurrencyDisplay value={value}/>
        }
        if (column.type === 'Selector') {
            return <div onClick={(ev) => {
                handleSelectorClick(ev, index)
            }}>
                <div className={'composer-selector-cell'} style={{ minWidth: '85px', width: '100%', height: '100%'}}>&nbsp;</div>
                <div className={'composer-selector-cell-hidden'}>&nbsp;<Icon
                    iconName={selectedRows.includes(index) ? 'Completed' : 'CircleRing'}></Icon></div>
            </div>
        }
        return <>{value}</>
    }

    const getRowClassName = (index: number) => {
        if (selectedRows.includes(index)) {
            return 'composer-row-selected'
        }
        return 'composer-row'
    }

    document.addEventListener('keydown', (event) => {
        if (event.key == 'Control') {
            event.preventDefault();
            setMultiSelectKeys(true)
        }
    });
    document.addEventListener('keyup', (event) => {
        if (event.key == 'Control') {
            setMultiSelectKeys(false)
        }
    });

    const handleRowSelectionClick = (index: number) => {
        if (props.selectionMode === GridSelectionMode.none) return
        else if (GridSelectionMode.multiple && (selectedRows.length == 0 || multiSelectKeys)) {
            if (selectedRows.includes(index)) {
                setSelectedRows(selectedRows.filter((r) => r !== index))
            } else {
                setSelectedRows((sr) => [...sr, index])
            }
        } else {
            setSelectedRows([index])
        }

    }

    const handleSelectAll = () => {
        if (props.selectionMode != GridSelectionMode.multiple) return
        if (selectedRows.length === props.items.length) {
            setSelectedRows([])
        } else {
            setSelectedRows(props.items.map((item, index) => index))
        }
    }

    const handleSelectorClick = (ev: React.MouseEvent<HTMLDivElement>, index: number) => {
        ev.stopPropagation();
        if (props.selectionMode === GridSelectionMode.none) return
        if (props.selectionMode === GridSelectionMode.single) {
            setSelectedRows([index])
        }
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter((r) => r !== index))
        } else {
            setSelectedRows((sr) => [...sr, index])
        }

    }

    const allSelected = () => {
        return props.items.length === selectedRows.length
    }

    const mouseDown = (index: number) => {
        setActiveIndex(index);
    }

    return (
        <div tabIndex={0}>
            <table width={'100%'} style={{borderSpacing: '0', overflow: 'auto'}} ref={tableElement}>
                <thead className={'composer-header-main'}>
                <tr>
                    {columnDetails?.map((column, index) => {
                        if (column.type === 'Selector' && props.selectionMode === GridSelectionMode.multiple) {
                            return (<React.Fragment key={`grid-header-container-${props.gridSource}-${column.identifier}-${index}${(props.isCustomizeModal? '-customize' : '')}`}>
                                    <th
                                        className={'composer-header'}
                                        key={`grid-header-${props.gridSource}-${column.identifier}-${index}${(props.isCustomizeModal? '-customize' : '')}`}
                                    >
                                        <div onClick={(ev) => {
                                            handleSelectAll()
                                        }}>
                                        <span>
                                            <div className={'composer-selector-cell'}
                                                 style={{width: '100%', height: '100%'}}>&nbsp;</div>
                                            <div className={'composer-selector-all-header-hidden'}><Icon
                                                iconName={selectedRows.includes(index) ? 'Completed' : 'CircleRing'}></Icon>
                                                <div
                                                    className={'composer-selector-all-header-label'}>{allSelected() ? 'Select None' : 'Select All'}</div>
                                            </div>
                                        </span>
                                        </div>

                                    </th>
                                </React.Fragment>
                            )
                        }
                        if (column.sortable || column.filterable) {
                            return (<React.Fragment key={`grid-header-container-${props.gridSource}-${column.identifier}-${index}${(props.isCustomizeModal? '-customize' : '')}`}>
                                <th className={'composer-header'}
                                    key={`grid-header-${props.gridSource}-${column.identifier}-${index}${(props.isCustomizeModal? '-customize' : '')}`}
                                >

                                    <div
                                        onDragOver={enableDropping}
                                        onDrop={(ev) => handleDrop(ev, column)}
                                        onDragEnter={handleDragOverStart}
                                        onDragLeave={handleDragOverEnd}
                                        style={dragOver ? {width: '100%', height: '100%', background: 'rgb(207, 220, 181)'} : {}}
                                    >
                                        <div id={`${column.identifier}`} draggable={!!props.canDrag}
                                             onDragStart={handleDragStart}
                                        >
                                        <span>
                                            <FilterableSortableColumnHeader
                                                tooltip={column.tooltip}
                                                filterOptions={column?.customFilterOptions?.()}
                                                field={column.identifier}
                                                label={column.label}
                                                filterable={column.filterable}
                                                sortable={column.sortable}
                                                filterValue={filterField === column.identifier.replace('.keyword', '') ? filter : ''}
                                                onFilterApply={(filter) => {
                                                    const normalizedFilterField = column.identifier.replace('.keyword', '')
                                                    if (!filter || filter === '') {
                                                        setFilterField('')
                                                        setFilter('')
                                                        props.onFilterChange('', undefined)
                                                    } else {
                                                        setFilterField(normalizedFilterField)
                                                        if(column.onCustomFilterApplied){
                                                            filter = column.onCustomFilterApplied(filter)
                                                        }
                                                        props.onFilterChange(filter, normalizedFilterField)
                                                        setFilter(filter ?? '')
                                                    }
                                                }}
                                                onSort={(sort) => {
                                                    setSortField(column.identifier)
                                                    if (sortDirection === 'desc') {
                                                        props.onSortChange(column.identifier, 'asc')
                                                        setSortDirection('asc')
                                                    } else {
                                                        props.onSortChange(column.identifier, 'desc')
                                                        setSortDirection('desc')
                                                    }
                                                }}
                                                sortedDescending={sortField === column.identifier ? (sortDirection === 'desc') : undefined}
                                            />
                                        </span>
                                        </div>
                                    </div>
                                </th>
                            </React.Fragment>);
                        }
                        return (
                            <th className={'composer-header'}
                                key={`grid-header-${props.gridSource}-${column.identifier}-${index}${(props.isCustomizeModal? '-customize' : '')}`}
                            >
                                <div id={`header-container-${index}`} draggable={!!props.canDrag}
                                     onDragStart={handleDragStart}>
                                    <span>{column.label}</span>
                                </div>
                                <div
                                    onDragOver={enableDropping}
                                    onDrop={(ev) => handleDrop(ev, column)}
                                    onDragEnter={handleDragOverStart}
                                    onDragLeave={handleDragOverEnd}
                                    style={dragOver ? {fontWeight: 'bold', background: 'rgb(207, 220, 181)'} : {}}
                                >
                                </div>
                            </th>);
                    })}
                </tr>
                </thead>
                <tbody>
                {props.loading ?
                    <React.Fragment>{getShimmer()}</React.Fragment> :
                    <React.Fragment>
                        {props.items.map((item, itemIndex) => {
                            return (
                                <tr
                                    id={`datagrid-row-${item.id}-${itemIndex}`}
                                    key={`datagrid-row-${item.id}-${itemIndex}${(props.isCustomizeModal? '-customize' : '')}`}
                                    className={getRowClassName(itemIndex)}
                                    onClick={() => {
                                        handleRowSelectionClick(itemIndex)
                                    }}
                                >
                                    {columnDetails?.map((column, index) => {
                                        let workingValue;
                                        if(props.isCustomizeModal){
                                            workingValue = item[column.identifier]
                                        }
                                        else {
                                            //.keyword needs to be appended to the identifier to make sorting work with elastic for string fields.

                                            const normalizedIdentifier = column.identifier.replace('.keyword', '')

                                            const segmentedIdentifier = normalizedIdentifier.split('.')
                                            workingValue = item[segmentedIdentifier[0]]
                                            for (let i = 0; i < segmentedIdentifier.length; i++) {
                                                if (!workingValue) break;
                                                const key = segmentedIdentifier?.[i + 1]
                                                if (!key) break;
                                                workingValue = workingValue[segmentedIdentifier[i + 1]]
                                            }
                                        }
                                        return <td className={'composer-cell'}
                                                   key={`datagrid-cell-${item.id}-${itemIndex}-${column.identifier}${(props.isCustomizeModal? '-customize' : '')}`}
                                                   id={`datagrid-cell-${item.id}-${itemIndex}-${column.identifier}`}
                                                   style={{
                                                       minWidth: (column.minimumWidth ? `${column.minimumWidth}px` : '50px'),
                                                       width: column.effectiveWidth ? `${column.effectiveWidth}px` : 'auto'
                                                   }}>
                                            <span>{getTypedValue(column, itemIndex, workingValue?.toString())}</span>
                                        </td>
                                    })}
                                </tr>
                            );
                        })}</React.Fragment>}
                </tbody>

            </table>
        </div>)
}
export default DataGrid;