import React from "react";
import { FontIcon, Callout, DefaultButton, Stack } from "@fluentui/react";
import { INormalizedProduct } from "../../models/NormalizedProduct";
import { useBoolean } from '@fluentui/react-hooks';
import {useSetUserFavoriteMutation, useRemoveUserFavoriteMutation, useSetCompanyFavoriteMutation, useRemoveCompanyFavoriteMutation} from "../../store/Api";

interface IFavoritesDisplay {
    product: INormalizedProduct;
    isExample?: boolean
}

export const FavoritesDisplay: React.FC<IFavoritesDisplay> = (props) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [setUserFavorite] = useSetUserFavoriteMutation();
    const [removeUserFavorite] = useRemoveUserFavoriteMutation();
    const [setCompanyFavorite] = useSetCompanyFavoriteMutation();
    const [removeCompanyFavorite] = useRemoveCompanyFavoriteMutation();
    const favorite = props.product.favorite;

    const companyColor = '#789c2f';
    const userColor = '#ffd700';

    let companyIcon = 'AddFavorite';
    let userIcon = 'AddFavorite';
    let starColor = '';

    if(favorite.company){
        companyIcon = 'FavoriteStarFill';
        starColor = companyColor;
    }
    if(favorite.user){
        userIcon = 'FavoriteStarFill';
        starColor = userColor;
    }

    const toggleUserFavorite = () => {
        if(props.isExample) return;
        if(favorite.user){
            removeUserFavorite({catalog: props.product.catalog, id: props.product.id});
        } else {
            setUserFavorite({catalog: props.product.catalog, id: props.product.id});
        }
    }

    const toggleCompanyFavorite = () => {
        if(props.isExample) return;
        if(favorite.company){
            removeCompanyFavorite({catalog: props.product.catalog, id: props.product.id});
        } else {
            setCompanyFavorite({catalog: props.product.catalog, id: props.product.id});
        }
    }

    return (
        <div>
            <FontIcon
                id={'Star'+props.product.id}
                iconName={favorite.company || favorite.user ? 'FavoriteStarFill' : 'AddFavorite'}
                style={{verticalAlign: 'middle', height: '20px', width: '20px', fontSize: '20px', cursor: 'pointer', color: starColor}}
                onClick={toggleIsCalloutVisible}
            />
            {isCalloutVisible && (
                <Callout
                    className={"styles.callout"}
                    gapSpace={0}
                    target={`#${'Star'+props.product.id}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <Stack>
                        <DefaultButton
                            text="Company"
                            iconProps={{iconName:companyIcon, style:{color:companyColor}}}
                            onClick={toggleCompanyFavorite}
                        />
                        <DefaultButton
                            text="User"
                            iconProps={{iconName:userIcon, style:{color:userColor}}}
                            onClick={toggleUserFavorite}
                        />
                    </Stack>
                </Callout>
            )}
        </div>
    );
}