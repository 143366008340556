import {useSelector} from "react-redux";
import {RootState} from "../store/rootReducer";
import {Ability, AbilityBuilder, PureAbility} from '@casl/ability';

export const SEARCH = "search";
export const EDIT_FAVORITES = "editFavorites";
export const EDIT_COMPANY_FAVORITES = "editCompanyFavorites";
export const EDIT_ITEM = "editItem";
export const SEARCH_HISTORY = "searchHistory";
export const EXTENSION_RUN = "extensionRun";
export const EXTENSION_LOAD = "extensionLoad"; // This one is only used internally for permissions on if the user can load any extensions
export const EXTENSION_MODIFY = "extensionModify"; // This one is only used internally for permissions on if the user can modify any extensions
export const EXTENSION_LOGS = "extensionLogs"; //This one is only used internally for permissions on if the user can view any extension logs
export const ORG_EXTENSION_LOAD = "orgExtensionLoad";
export const ORG_EXTENSION_MODIFY = "orgExtensionModify";
export const ORG_EXTENSION_LOGS = "orgExtensionLogs";
export const USER_EXTENSION = "userExtension";
export const CATALOG_MODIFY = "catalogModify";
export const CATALOG_BULK_LOAD = "catalogBulkLoad";
export const USER_LIST = "userList";
export const USER_MODIFY = "userModify";
export const USER_DETAILS = "userDetails";
export const ROLE_MODIFY = "roleModify";
export const ROLE_ASSIGN = "roleAssign";
export const ORG_REPORTING = "orgReporting";
export const ORG_MODIFY = "orgModify";
export const API_KEYS_ADMIN = "apiKeysAdmin";
export const API_KEYS_SELF = "apiKeysSelf";
export const TAX_CODE_MODIFY = "taxCodeModify";
export const LLM_ANALYSIS = "llmAnalysis";
export const DOCUMENT_ANALYSIS = "documentAnalysis";
export const CUSTOMER_REQUEST_ANALYSIS = "customerRequestAnalysis";
export const PROCUREMENT = "procurement";
export const STOREFRONT_READ = "storefrontRead";
export const STOREFRONT_ADMIN = "storefrontAdmin";
export const SAVED_SEARCH_READ = "savedSearchRead"
export const SAVED_SEARCH_ADMIN = "savedSearchAdmin"
export const REPORT_READ = "reportsRead"
export const REPORT_GENERATE = "reportsGenerate"



const ability = new PureAbility();
export default (action: string) => {
    ability.update(createAbility())
    return ability.can('enact', action);
};

export function createAbility() {
    const {can, rules} = new AbilityBuilder(Ability);
    const permissions = useSelector((state: RootState) => state.settings.effectiveClaims);

    if(permissions) {
        permissions.forEach( function(permission) {can('enact', permission)});
    }

    return rules;
}
