import React, {useEffect, useState} from "react";
import {
    Checkbox,
    DefaultButton,
    Dialog, DialogFooter, 
    DialogType,
    Dropdown,
    IDropdownOption,
    MessageBar,
    MessageBarType,
    Panel,
    PrimaryButton,
    Stack, TextField
} from "@fluentui/react";
import {
    useGetAvailableGridConfigurationsQuery,
    useGetGridAssignmentQuery,
    useGetGridConfigurationQuery, 
    useGetReportGenerationStatusQuery, 
    useQueueReportMutation
} from "../../store/Api";
import {IDropdownStyles} from "@fluentui/react/lib/Dropdown";

interface ICreateCsvPanelProps{
    gridIdentifier: string;
    isOpen: boolean;
    onClose: () => void;
}

const CreateCsvPanel: React.FC<ICreateCsvPanelProps> = (props) => {
    const [selectedGridOption, setSelectedGridOption] = useState<string>();
    const [gridOptions, setGridOptions] = useState<IDropdownOption[]>([]);

    const getColumns = useGetGridConfigurationQuery({gridIdentifier: props.gridIdentifier, gridIdHint: selectedGridOption ?? ''}, {skip: (!props.gridIdentifier || props.gridIdentifier === '' || !selectedGridOption || selectedGridOption === '' || selectedGridOption === 'custom')});
    const getColumnSets = useGetAvailableGridConfigurationsQuery(props.gridIdentifier, {skip: (!props.gridIdentifier || props.gridIdentifier === '')});
    const getGridAssignment = useGetGridAssignmentQuery(props.gridIdentifier, {skip: (!props.gridIdentifier || props.gridIdentifier === '')});
    const [queueGenerateCsv] = useQueueReportMutation();
    const [reportId, setReportId] = useState<string>();
    const [generating, setGenerating] = useState<boolean>(false);
    const [creatingLog, setCreatingLog] = useState<boolean>(false);

    const getReportGenerationStatus = useGetReportGenerationStatusQuery(reportId ?? '', {skip: creatingLog || !creatingLog && (!reportId || reportId === '')});
    const [searchTerm, setSearchTerm] = useState<string>();
    const [searchBy, setSearchBy] = useState<string>();
    const [sortBy, setSortBy] = useState<string>();
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [pageSize, setPageSize] = useState<string>('25');
    const [statusMessage, setStatusMessage] = useState<string>();
    const [displayModal, setDisplayModal] = useState<boolean>();
    const [csvLink, setCsvLink] = useState<string>();
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [sortByOptions, setSortByOptions] = useState<IDropdownOption[]>([]);
    const [searchByOptions, setSearchByOptions] = useState<IDropdownOption[]>([]);
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        let timeout: NodeJS.Timeout | undefined;

        if (reportId) {
            interval = setInterval(() => {
                getReportGenerationStatus.refetch();
            }, 15000);
            timeout = setTimeout(() => {
                clearInterval(interval);
                setStatusMessage('CSV generation timed out');
                setCsvLink(undefined);
                setReportId(undefined);
                setGenerating(false);
            }, 900000); // 15 minutes
        }
        //Avoid short-circuit when doing successive reports
        if(reportId && reportId === getReportGenerationStatus?.data?.id) {
            if (getReportGenerationStatus.data?.status === 'Completed') {
                clearInterval(interval);
                setCsvLink(getReportGenerationStatus.data.url);
                setReportId(undefined);
                setDisplayModal(true);
                setGenerating(false)
        
            } else if (getReportGenerationStatus.data?.status === 'Failed') {
                clearInterval(interval);
                setStatusMessage('Error generating CSV \r\n' + getReportGenerationStatus.data.message);
                setCsvLink(undefined);
                setReportId(undefined);
                setGenerating(false);
            }
        }
    
        return () => {
            if (interval) {
                clearInterval(interval);
            }
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [reportId, getReportGenerationStatus]);
    useEffect(() => {
        if(getGridAssignment.data){
            setSelectedGridOption(getGridAssignment.data.assignment);
        }
    }, [getGridAssignment]);

    useEffect(() => {
        if(getColumns.data){
            setSelectedColumns(() => (getColumns.data?.visibleColumns?.filter(a => a != 'selector') ?? []));
        }
    }, [getColumns]);

    useEffect(() => {
        const sortBy: IDropdownOption[] = []
        const filterBy: IDropdownOption[] = []
        if(getColumns.data) {
            selectedColumns.forEach(column => {
                const filterIndex = getColumns.data?.columnDetails?.findIndex(a => a.identifier == column && a.sortable)
                const sortIndex = getColumns.data?.columnDetails?.findIndex(a => a.identifier == column && a.filterable)
                if(sortIndex && sortIndex != -1){
                    const column = getColumns?.data?.columnDetails?.[sortIndex];
                    if(column) {
                        filterBy.push({key: column.identifier, text: column.label});
                    }
                }
                if(filterIndex && filterIndex != -1){
                    const column = getColumns?.data?.columnDetails?.[filterIndex];
                    if(column) {
                        sortBy.push({key: column.identifier, text: column.label});
                    }
                }
                setSearchByOptions(() => [...filterBy]);
                setSortByOptions(() => [...sortBy])
            })
        }
    }, [selectedColumns]);
    
    useEffect(() => {
        const columnSets = []
        if(getColumnSets.data){
            columnSets.push(...getColumnSets.data.map((v) => ({key: v.id ?? "", text: v.name ?? ""})))
        }
        setGridOptions(columnSets);
    }, [getColumnSets]);

    const onSelectColumns = (item: IDropdownOption | undefined): void => {
        if (item) {
            const columnsUpdated = item.selected
                ? [...selectedColumns, item.key as string]
                : [...selectedColumns.filter(key => key !== item.key)]

            setSelectedColumns( () =>[...columnsUpdated]);
        }
    }
    
    const pageSizeOptions = [
        {
            key: "25",
            text: "25",
            onClick: () => setPageSize('25')
        },
        {
            key: "50",
            text: "50",
            onClick: () => setPageSize('50')
        },
        {
            key: "100",
            text: "100",
            onClick: () => setPageSize('100')
        },
        {
            key: "250",
            text: "250",
            onClick: () => setPageSize('250')
        },
        {
            key: "1000",
            text: "1000",
            onClick: () => setPageSize('1000')
        },
    ]
    
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: {width: 300,},
    };
    return(<div>
        <Panel
            onOuterClick={() => {console.log("swallowing modal close event")}}
            onDismiss={() => {
                props.onClose();
                setCsvLink(undefined);
                setReportId(undefined);
                setGenerating(false);
                setStatusMessage('');
                setCreatingLog(false);
            }}
            isOpen={props.isOpen}
            headerText={'Create CSV'}
            styles={{
                    root: {
                        zIndex: 10, 
                        marginTop: '48px',
                    }}}
            onRenderFooterContent={() => (
            <div className={'fixed-footer'}>
                <Stack horizontal>
                    <PrimaryButton disabled={generating} text={generating? 'Generating': 'Generate CSV'} style={{width: '100%'}} onClick={() => {
                        setGenerating(true)
                        setStatusMessage('');
                        queueGenerateCsv(
                            {
                                entityType: props.gridIdentifier,
                                reportType: 'storefrontOrderCsv',
                                gridId: (selectedGridOption != 'custom' ? selectedGridOption : undefined), 
                                lookupConditions: {
                                    page: 1,
                                    pageSize: parseInt(pageSize, 10),
                                    searchTerm: searchTerm,
                                    searchBy: searchBy,
                                    sortBy: sortBy,
                                    sortDirection: sortDirection,
                                    includedFields: selectedColumns
                                    
                                }})
                            .unwrap()
                            .then((resp) => {
                                if(resp.success && resp.id){
                                    setReportId(resp.id);
                                    setCreatingLog(false)
                                }
                                else{
                                    setReportId(undefined);
                                    console.log('bailing out!', resp.message)
                                    setStatusMessage(resp.message ?? 'Error creating CSV');
                                    setCreatingLog(false)
                                    setGenerating(false)
                                }
                            });
                    }}/>
                </Stack>
            </div>)}
            isFooterAtBottom={true}>
            {statusMessage && <MessageBar messageBarType={MessageBarType.error}>{statusMessage}</MessageBar>}
            <Dropdown
                disabled={generating}
                options={gridOptions}
                label={'Column Sets'}
                selectedKey={selectedGridOption ?? 'custom'}
                onChange={(_e, selectedItem) => {setSelectedGridOption(selectedItem?.key as string)}}
            />
            <Dropdown
                disabled={generating}
                placeholder="Select options"
                label="Select Columns"
                selectedKeys={selectedColumns}
                multiSelect
                options={getColumns?.data?.columnDetails?.map(a => ({
                    key: a.identifier,
                    text: a.label,
                })) ?? []}
                styles={dropdownStyles}
                onChange={(_e, selectedItem) => onSelectColumns(selectedItem)}
            />
            {sortByOptions && 
            <Dropdown
                disabled={generating}
                placeholder="Select sort field"
                label="Sort By"
                selectedKey={sortBy}
                options={sortByOptions}
                styles={dropdownStyles}
                onChange={(_e, selectedItem) => setSortBy(() => selectedItem?.key as string)}
            />}
            {sortBy && <div style={{paddingTop: '1em'}}>
                <Checkbox
                    disabled={generating}
                    label={'Descending'}
                    checked={sortDirection == 'desc'}
                    onChange={() => sortDirection == 'desc' ? setSortDirection('asc') : setSortDirection('desc')}
                /></div>}
            {searchByOptions &&
            <Dropdown
                disabled={generating}
                placeholder="Select filter field"
                label="Filter By"
                selectedKey={searchBy}
                options={searchByOptions}
                styles={dropdownStyles}
                onChange={(_e, selectedItem) => setSearchBy(() => selectedItem?.key as string)}
            />}
            {searchBy && 
            <TextField
                disabled={generating}
                label={'Filter'}
                value={searchTerm}
                onChange={(_e, value) => setSearchTerm(value)}
            />
            }
            <Dropdown
                disabled={generating}
                placeholder="Select result count"
                label="Number of records"
                selectedKey={pageSize}
                options={pageSizeOptions}
                styles={dropdownStyles}
                onChange={(_e, selectedItem) => setPageSize(() => selectedItem?.key as string)}
            />
        </Panel>

        <Dialog
            hidden={!displayModal}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'CSV Generated',
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <a href={csvLink}  target={"new"} title={'Open CSV'}>Open CSV</a>
            <DialogFooter>
                <DefaultButton
                    primary
                    text='OK'
                    onClick={() => {
                        setDisplayModal(false)
                    }}
                />
            </DialogFooter>

        </Dialog>
    </div>)
}
export default CreateCsvPanel;
