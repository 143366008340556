import React, {useEffect, useRef, useState} from 'react';
import {
    CommandBar, 
    ContextualMenuItemType,
    ICommandBarItemProps, 
    IContextualMenuItem,
    Selection
} from '@fluentui/react';
import Paginator from "../Common/Paginator";

import DataGrid from "../DataGrid/DataGrid";
import {ILooseCollectionResponse} from "../../models/ICollectionResponse";
import {GridSelectionMode, IColumnDetails} from "../../models/GridConfiguration";
import Sticky from "react-stickynode";
import GridColumnEditModal from "./GridColumnEditModal";
import {
    useGetAvailableGridConfigurationsQuery, useGetGridAssignmentQuery,
    useGetGridConfigurationQuery,
    useSaveGridAssignmentMutation
} from "../../store/Api";
import CreateCsvPanel from "./CreateCsvPanel";
import CAN, {REPORT_GENERATE, REPORT_READ} from "../../permissions/ability"

interface IGridComposerProps {
    columnSetName: string;
    data: ILooseCollectionResponse;
    onSelectOptions?: (value: any[]) => void;
    loading: boolean;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    selectionMode: GridSelectionMode;
    filter?: string;
    filterField?: string;
    sortBy?: string;
    sortDirection?: string;
    onSort: (column?: string, sortDirection?: string) => void;
    onFilter: (filter?: string, column?: string) => void;
    overrideColumns?: IColumnDetails[]
    canDragColumns?: boolean;
    canResizeColumns?: boolean;
    canCustomizeColumns?: boolean;
    canChangePageSize?: boolean;
    canCreateCsv?: boolean;
}
const GridComposer: React.FC<IGridComposerProps> = (props) => {

    const [page, setPage] = useState<number>(1)
    const [totalItemCount, setTotalItemCount] = useState<number>()
    const [pageSize, setPageSize] = useState<number>(25)
    const [editColumnsModalOpen, setEditColumnsModalOpen] = useState<boolean>()
    const [effectiveItems, setEffectiveItems] = useState<any[]>()
    const [saveGridAssignment] = useSaveGridAssignmentMutation();
    const [gridAssignment, setGridAssignment] = useState<string | undefined>()
    const [displayCsvModal, setDisplayCsvModal] = useState(false)
    const getColumns = useGetGridConfigurationQuery({gridIdentifier: props.columnSetName, gridIdHint: gridAssignment ?? ''}, {skip: (!props.columnSetName || props.columnSetName === '' || !gridAssignment || gridAssignment === '')});
    const getColumnSets = useGetAvailableGridConfigurationsQuery(props.columnSetName, {skip: (!props.columnSetName || props.columnSetName === '')});
    const getGridAssignment = useGetGridAssignmentQuery(props.columnSetName, {skip: (!props.columnSetName || props.columnSetName === '')});

    useEffect(() => {
        if(getGridAssignment.data){
            setGridAssignment(getGridAssignment.data?.assignment)
        }
    },[getGridAssignment.data])
    const updateGridAssignment = (gridAssignment?: string) => {
        setGridAssignment(() => gridAssignment)
        if(gridAssignment) {
            saveGridAssignment({gridIdentifier: props.columnSetName, gridId: gridAssignment})
        }
    }

    const changePage = (page: number) => {
        setPage(page)
        props.onPageChange(page)
    }
    const changePageSize = (pageSize: number) => {
        setPageSize(pageSize)
        props.onPageSizeChange(pageSize)
    }
    useEffect(() => {
        const currentData = props.data
        setEffectiveItems(() => [...currentData.items ?? []])
        setTotalItemCount(currentData.total)
    }, [page, pageSize, props.data]);
    
    const getStartCount = () => {
        if(totalItemCount) {
            return (page*pageSize)-(pageSize-1)
        }
        return 0
    }
    const getEndCount = () => {
        if(totalItemCount){
            const max = page*pageSize
            return max > totalItemCount ? totalItemCount : max
        }
    }
    const rightCommands: ICommandBarItemProps[] = [
        {
            disabled: true,
            key: 'page',
            text: totalItemCount ? `${getStartCount()}-${getEndCount()} of ${totalItemCount}` : '',
        }
    ]

    const rightCommandsWithPageSize: ICommandBarItemProps[] = [
        {
            key: 'page',
            split: true,
            text: totalItemCount ? `${getStartCount()}-${getEndCount()} of ${totalItemCount}` : '',
            subMenuProps:{
                items: [
                    {
                        key: "sectionTitlePageSize",
                        text: "Select Page Size",
                        itemType: ContextualMenuItemType.Header
                    },
                    {
                        key: "pageSize25",
                        text: "25",
                        onClick: () => changePageSize(25),
                        disabled: pageSize === 25
                    },
                    {
                        key: "pageSize50",
                        text: "50",
                        onClick: () => changePageSize(50),
                        disabled: pageSize === 50                       
                    },
                    {
                        key: "pageSize100",
                        text: "100",
                        onClick: () => changePageSize(100),
                        disabled: pageSize === 100
                    },
                    {
                        key: "pageSize250",
                        text: "250",
                        onClick: () => changePageSize(250),
                        disabled: pageSize === 250
                    }
                    
                ]
            }
        }
    ]

    const commands: ICommandBarItemProps[] =[
        {
            key: 'customize',
            text: 'Customize Columns',
            iconProps: {iconName: 'edit'},
            onClick: () => setEditColumnsModalOpen(true)
        },
    ]
    
    const splitCommandEntries: IContextualMenuItem[] = [
        {
            key: "sectionTitle",
            text: "Available Column Configurations",
            itemType: ContextualMenuItemType.Header
        }, 
        ...getColumnSets.data?.map((item) => (
        {
            key: item.id ?? '',
            disabled: item.id === getColumns.data?.id,
            text: item.name, 
            onClick: () => {updateGridAssignment(item.id)},
        })) ?? []
    ]
    
    const splitCommands: ICommandBarItemProps[] =[
        {
            key: 'customize',
            text: 'Customize Columns',
            iconProps: {iconName: 'edit'},
            onClick: () => setEditColumnsModalOpen(true),
            subMenuProps:{
                items: splitCommandEntries,
            },
            split: true
        },
    ]
    if(props.canCreateCsv && CAN(REPORT_GENERATE) && CAN(REPORT_READ)){
        const csvCommand = {
            key: 'GenerateCsv',
            text: 'Export to CSV',
            iconProps: {iconName: 'Export'},
            onClick: () => {
                setDisplayCsvModal(true)
            }
        }
        splitCommands.push(csvCommand)
        commands.push(csvCommand)
    }
    const selection = new Selection({
        onSelectionChanged: () => {
            if (!props.onSelectOptions) return;
            const items = selection.getSelection() as any[] | undefined;
            props.onSelectOptions(items ?? []);
        },
    });

    return (<div style={{overflowX:'auto', width: '100%'}}>
            {(totalItemCount && totalItemCount > pageSize) &&
                <Paginator
                    page={page}
                    resultCount={totalItemCount}
                    onChange={(p) => {
                        changePage(p)
                    }}
                    pageSize={pageSize}
                />}
        {props.canCustomizeColumns && 
        <CommandBar items={getColumnSets.data && getColumnSets.data.length > 0 ? splitCommands : commands} 
                    farItems={props.canChangePageSize ? rightCommandsWithPageSize : rightCommands}
        />
        }
            <DataGrid 
                enableShimmer={props.loading || getGridAssignment.isFetching || getGridAssignment.isLoading || getColumnSets.isFetching || getColumnSets.isLoading}
                items={effectiveItems ?? []}
                selectionMode={props.selectionMode} 
                gridSource={props.columnSetName}
                gridId={gridAssignment}
                selections={[]}
                loading={props.loading || getGridAssignment.isFetching || getGridAssignment.isLoading || getColumnSets.isFetching || getColumnSets.isLoading}
                onFilterChange={(filter, field) => {
                    changePage(1)
                    props.onFilter(filter, field)
                }}
                onSortChange={(column, sortDirection) => { 
                    changePage(1)
                    props.onSort(column, sortDirection) 
                }}
                overrideColumns={props.overrideColumns}
                canDrag={props.canDragColumns}
                canResize={props.canResizeColumns}
            />
            {(totalItemCount && totalItemCount > pageSize) &&
                <Sticky enabled={true} top={100} innerZ={20}>
                <Paginator
                    page={page}
                    resultCount={totalItemCount}
                    onChange={(p) => {
                        changePage(p)
                    }}
                    pageSize={pageSize}
                /></Sticky>}
            <GridColumnEditModal
                setName={props.columnSetName}
                isOpen={editColumnsModalOpen == true}
                onClose={() => {
                    setEditColumnsModalOpen(false)
                }}
                onSave={(columns, assignment) => {
                    setEditColumnsModalOpen(false)
                    if(assignment) {
                        setGridAssignment(assignment)
                    }
                }}
            />
            <CreateCsvPanel
                gridIdentifier={props.columnSetName}
                isOpen={displayCsvModal}
                onClose={() => {setDisplayCsvModal(false)}}
            />

            {props.children}
        </div>
    );
}

export default GridComposer;