import {MutableRefObject, ReactElement} from "react";

export interface IGridConfiguration {
    id?: string;
    name?: string;
    source?: string;
    shared?: boolean;
    visibleColumns?: string[];
    columnDetails?: IColumnDetails[];
    gridIdentifier?: string;
    username?: string;
}

export interface IGridAssignmentResponse {
    assignment: string;
}

export interface IGridSampleData{
    fieldIdentifier: string;
    value: string;
}


export interface IColumnDetails {
    index?: number;
    label: string;
    type: string;
    identifier: string;
    minimumWidth?: number;
    effectiveWidth?: number;
    columnDefault?: boolean;
    sortable?: boolean;
    filterable?: boolean;
    component?: (object?: any) => ReactElement
    onClick?: (object?: any) => any;
    onBlur?: (item: any, value: string) => any;
    customFilterOptions?: () => {key: string, text: string}[] | undefined;
    onCustomFilterApplied?: (input: string) => string;
    tooltip?: string;
    ref?: MutableRefObject<HTMLElement | undefined>
    required?: boolean;
}

export enum GridSelectionMode {
    none = 0,
    single = 1,
    multiple = 2
}