import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGetRecentWorkspaceRefsQuery, useSetSelectedWorkspaceMutation } from '../../store/Api';
import { DocumentCard, DocumentCardTitle, Stack } from '@fluentui/react';

const WorkspaceList: React.FC = () => {
    const history = useHistory();
    const workspaceQuery = useGetRecentWorkspaceRefsQuery();
    const [setSelectedWorkspace] = useSetSelectedWorkspaceMutation();

    return (
        <div className={`home-list workspace-list ${workspaceQuery.data ? 'fade-in' : 'hide'}`}>
            <h3>Workspaces</h3>
            <Stack tokens={{ childrenGap: '0.5em' }}>
                {workspaceQuery.data?.map((workspace) => (
                    <DocumentCard key={workspace.id} onClick={() => {
                        setSelectedWorkspace(workspace.id).then(() => {
                            history.push(`/workspace/${workspace.id}`);
                        });
                    }}>
                        <DocumentCardTitle className={'home-workspace-title'} title={workspace.name ?? ''} />
                    </DocumentCard>
                ))}
            </Stack>
        </div>
    );
}

export default WorkspaceList;