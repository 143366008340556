import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {IDirectImportItem} from '../../models/Extension';
import {useGetColumnSetQuery, useLazyGetDirectImportPreviewQuery} from "../../store/Api";
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    IGroup,
    Selection,
    SelectionMode,
    ShimmeredDetailsList,
} from "@fluentui/react";
import SortableDirectImportPreviewColumnHeader from "./SortableDirectImportPreviewHeader";
import {
    DEMO_DIRECT_IMPORT_ITEMS, DIRECT_IMPORT_COLUMN_PROPERTIES, getDirectImportColumnOptions,
    renderDirectImportColumns
} from "../../logic/Columns/DirectImportItemColumns";
import Paginator from "../Common/Paginator";
import CustomColumnEditModal from "../ColumnCustomization/CustomColumnEditModal";
import {DEFAULTDIRECTIMPORTCOLUMNS} from "../../Constants";
import FilterableSortableColumnHeader from "./SortableDirectImportPreviewHeader";

interface IExtensionDirectImportPreviewProps {
    fieldName: string;
    importObjectId?: string;
    sourceName?: string;
    extensionId: string;
    onSelectOption?: (value: IDirectImportItem[]) => void;
}

const ExtensionDirectImportPreview: React.FC<IExtensionDirectImportPreviewProps> = (props) => {
    const COLUMN_SET_NAME = 'ExtensionDirectImportPreview'
    const [initialOptions, setInitialOptions] = useState<IDirectImportItem[]>([])
    const [getImportItems] = useLazyGetDirectImportPreviewQuery()
    const [sortable, setSortable] = useState<string[]>([])
    const [filterable, setFilterable] = useState<string[]>([])
    const [currentFilterField, setCurrentFilterField] = useState<string>()
    const [currentFilterValue, setCurrentFilterValue] = useState<string>()
    const currentSort = useRef<string>()
    const [sortByDescending, setSortByDescending] = useState<boolean>()
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>()
    const [totalItemCount, setTotalItemCount] = useState<number>()
    const [displayedColumns, setDisplayedColumns] = useState<string[]>([])
    const [pageSize, setPageSize] = useState<number>(25)
    const [groups, setGroups] = useState<IGroup[]>();
    const [editColumnsModalOpen, setEditColumnsModalOpen] = useState<boolean>()
    const [columns, setColumns] = useState<IColumn[]>()
    const getCustomColumns = useGetColumnSetQuery({columnType: COLUMN_SET_NAME});

    useLayoutEffect(() => {
        const columns = buildColumns(displayedColumns, filterable, sortable,
            (column)=>{
                sortColumn(column)
            },
            (columnToFilter, filter) => {
                setCurrentFilterField(columnToFilter);
                setCurrentFilterValue(filter);
                setPage(1)
            },
            checkIsSortedDescending, currentFilterField, currentFilterValue)
        setColumns(columns)
    }, [initialOptions, sortable, filterable, currentFilterField, currentFilterValue, currentSort, sortByDescending, displayedColumns]);
    useEffect(() => {
        if(getCustomColumns.data?.columns && getCustomColumns.data.columns.length > 0) {
            setDisplayedColumns(getCustomColumns.data.columns)
        }
        else {
            setDisplayedColumns(DEFAULTDIRECTIMPORTCOLUMNS)
        }
    }, [getCustomColumns.data]);
    useEffect(() => {
        const groupings: IGroup[] = []
        setLoading(true)
        getImportItems({
            fieldName: props.fieldName,
            extensionId: props.extensionId,
            request:{
                sourceName: props.sourceName ?? "",
                objectId: props.importObjectId,
                sortBy: currentSort.current,
                filterValue: currentFilterValue,
                filterField: currentFilterField,
                page: page,
                sortByDescending: sortByDescending,
                pageSize: pageSize
            }
        }).unwrap().then((responseData) => {
            if (responseData) {
                setSortable(responseData.sortableFields ?? [])
                setFilterable(responseData.filterableFields ?? [])
                setTotalItemCount(responseData.totalItemCount)
            }
            if (!responseData?.items) return
            if (responseData.totalItemCount && responseData.totalItemCount > 0) {
                console.log('total items:', responseData.totalItemCount)
                setInitialOptions(responseData.items)
            }
            //Haven't decided yet if grouping should be culled. It's not very useful for sorting/filtering
            else {
                const sorted = [...responseData.items].sort(sorter)
                const sortStarted = sorted.findIndex(a => a.acProduct)
                const adHocGroup: IGroup = {
                    key: `import_group_1`,
                    name: 'Ad-Hoc Items',
                    startIndex: 0,
                    count: (sortStarted === -1 ? sorted.length : sortStarted),
                    isCollapsed: false
                }
                const acGroup: IGroup = {
                    key: `import_group_2`,
                    name: 'Existing Items',
                    startIndex: sortStarted,
                    count: sortStarted === -1 ? 0 : (sorted.length - sortStarted),
                    isCollapsed: false
                }
                groupings.push(acGroup, adHocGroup)
                setInitialOptions(sorted)
                setGroups(groupings)
            }
            setLoading(false)
        })
    }, [page, currentFilterField, currentFilterValue, currentSort.current, props.extensionId, sortByDescending])

    const sortColumn = (column?: string) => {
        currentSort.current = column
        setPage(1)
        if(!sortByDescending) setSortByDescending(true)
        if(sortByDescending == true) setSortByDescending(false)
    }

    const checkIsSortedDescending = (column?: string) : boolean | undefined => {
        if(currentSort.current && column && currentSort.current == column && sortByDescending) {return true}
        else if(currentSort.current && column && currentSort.current == column) {return false}
        else return undefined
    }
    const getStartCount = () => {
        if(totalItemCount) {
            return (page*pageSize)-(pageSize-1)
        }
        return 0
    }
    const getEndCount = () => {
        if(totalItemCount){
            const max = page*pageSize
            return max > totalItemCount ? totalItemCount : max
        }
    }
    const rightCommands: ICommandBarItemProps[] = [
        {
            disabled: true,
            key: 'page',
            text: totalItemCount ? `${getStartCount()}-${getEndCount()} of ${totalItemCount}` : '',
        }
    ]

    const commands: ICommandBarItemProps[] =[
        {
            key: 'customize',
            text: 'Customize Columns',
            iconProps: {iconName: 'edit'},
            onClick: () => setEditColumnsModalOpen(true),
        },
    ]
    const sorter = ( object1: IDirectImportItem, object2: IDirectImportItem ) =>{
        if ((object1?.acProduct ?? "") < (object2?.acProduct ?? ""))
            return -1;
        if ((object1?.acProduct ?? "") > (object2?.acProduct ?? ""))
            return 1;
        return 0;
    }

    const buildColumns = (customColumns?: string[], filterableColumns?: string[], sortableColumns?: string[],
                          onSort?: (columnToSort?: string) => void, onFilter?: (filter?: string, filterValue?: string) => void,
                          checkSorted?: (column?: string) => boolean | undefined, currentFilterColumn?: string, currentFilterValue?: string): IColumn[] => {
        const autoColumns = renderDirectImportColumns();
        const effectiveColumns: IColumn[] = []
        if(customColumns && customColumns.length > 0){
            for(const col of customColumns){
                const colIndex = autoColumns.findIndex(a => a.fieldName == col)
                if(colIndex != -1){
                    effectiveColumns.push(autoColumns[colIndex])
                }
            }
        }
        else{
            Object.assign(effectiveColumns, autoColumns)
        }

        effectiveColumns.forEach((column: IColumn) => {
            const isSortedDescending = checkSorted ? checkSorted(column.fieldName) : false
            const filterable = column.fieldName != undefined && filterableColumns?.includes(column.fieldName)
            const sortable = column.fieldName != undefined && sortableColumns?.includes(column.fieldName)

            if(filterable || sortable){
                column.minWidth = 200
                column.onRenderHeader = () => {
                    return(
                        <FilterableSortableColumnHeader
                            sortable={sortable}
                            filterable={filterable}
                            label={column.name}
                            sortedDescending={isSortedDescending}
                            onSort={(column) => {if(onSort)onSort(column)}}
                            field={column.fieldName ?? column.key}
                            onFilterApply={(filter) => {if(onFilter) onFilter(column.fieldName, filter)}}
                            filterValue={currentFilterColumn == column.fieldName ? currentFilterValue : ''}
                            filterMessageCondition={(value) => props.sourceName?.includes('connectwise') ?? false}
                            filterMessage={'ConnectWise PSA API does not allow filters where the first character is a number'}
                        />)
                }
            }
        });
        return effectiveColumns;
    }

    const selection = new Selection({
        onSelectionChanged: () => {
            if (!props.onSelectOption) return;
            const items = selection.getSelection() as IDirectImportItem[] | undefined;
            props.onSelectOption(items ?? []);
        },
    });
    return (
        <>
            {(totalItemCount && totalItemCount > pageSize) &&
                <Paginator
                    page={page}
                    resultCount={totalItemCount}
                    onChange={(p) => {
                        setPage(p)
                    }}
                    pageSize={pageSize}
                />}
            <CommandBar items={commands} farItems={rightCommands}/>
            <ShimmeredDetailsList
                items={initialOptions}
                columns={columns}
                enableShimmer={loading}
                selectionMode={SelectionMode.multiple}
                selection={selection}
            />
            {(totalItemCount && totalItemCount > pageSize) &&
                <Paginator
                    page={page}
                    resultCount={totalItemCount}
                    onChange={(p) => {
                        setPage(p)
                    }}
                    pageSize={pageSize}
                />}
            <CustomColumnEditModal
                setName={"ExtensionDirectImportPreview"}
                isOpen={editColumnsModalOpen == true}
                defaultColumns={DEFAULTDIRECTIMPORTCOLUMNS}
                onClose={() => {
                    setEditColumnsModalOpen(false)
                }}
                onSave={setDisplayedColumns}
                testItems={DEMO_DIRECT_IMPORT_ITEMS}
                options={getDirectImportColumnOptions()}
                columnMetadata={DIRECT_IMPORT_COLUMN_PROPERTIES}/>
        </>
    );
}

export default ExtensionDirectImportPreview;