import React from 'react';
import {IWorkspaceItem} from "../../models/Workspace";
import {usePutWorkspaceItemDetailsMutation} from "../../store/Api";
import {Dropdown} from "@fluentui/react/lib/Dropdown";

interface IPriceLevelSelection {
    item: IWorkspaceItem;
    isExample?: boolean;
}

const PriceLevelSelection: React.FC<IPriceLevelSelection> = (props) => {
    const [detailsChange] = usePutWorkspaceItemDetailsMutation();
    if (props.item.product?.priceLevels == null || props.item.product.priceLevels.length == 0)
        return <></>
    return <Dropdown
        selectedKey={props.item.details.priceLevelPartNumber ?? 'NOT SET'}
        options={[
            {key: 'NOT SET', text: 'Not Set'},
            ...props.item.product.priceLevels.map(pl => ({
                key: pl.partNumber,
                text: pl.endQuantity ? `${pl.startQuantity} - ${pl.endQuantity}` : `${pl.startQuantity}+`
            })),
        ]}
        onChange={(e,d) => {
            if (d && !props.isExample) {
                detailsChange({
                    workspace: props.item.workspace,
                    product: props.item.id,
                    details: {
                        ...props.item.details,
                        priceLevelPartNumber: d.key === 'NOT SET' ? undefined : (d.key ?? '') as string
                    }
                })
            }
        }}
        />
}

export default PriceLevelSelection;